import colors from "./colors";
import {
  createTheme
} from "@material-ui/core/styles";

const theme = {
  colors: {
    tableBackground: colors.reallyLightGray,
    inactiveSeconday: colors.mediSecondaryGray,
    inactive: colors.mediLightGray,
    active: colors.mediMagenta,
    backgroundActive: colors.mediLightGray,
    text: colors.mediGray,
  },
  form: { minWidth: 186 },
};

export const materialTheme = createTheme({
  typography: {
    fontFamily: "medistradapro, Raleway, Arial",
    fontSize: 14,
  },
  palette: {
    primary: {
      light: "#48586a",
      main: "#48586a",
      dark: "#48586a",
      contrastText: "#48586a",
    },
    secondary: {
      light: "#e6007e",
      main: "#e6007e",
      dark: "#e6007e",
      contrastText: "#48586a",
      },
      
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        marginRight: 10,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiStepper: {
      root: {
        dispaly: "flex",
        paddingTop: 24,
        paddingBottom: 50,
        paddingLeft: 0,
        paddingRight: 0,
        "&.MuiPaper-root": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiStepIcon: {
      root: {
        "&.MuiStepIcon-active": { color: colors.mediMagenta }
      },
      text: {
        display: "none"
      }
    },
    MuiListItem: {
      container: {
        background: "white",
        marginBottom: 8
      },
      gutters: {
        marginBottom: 8,
        background: "white"
      }
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: colors.mediMagenta,
        color: "white"
      }
    },
    MuiTab: {
      root:{       
        backgroundColor: colors.mediGray,
        textTransform: "none",
        "&.MuiButtonBase-root": {
          color: "white",
          opacity: 1,
          borderBottomColor: colors.mediMagenta,
          borderWidth: 0,
          border: "solid",
          borderBottomWidth: 2,
        },        
        "&.Mui-selected": {
          color: colors.mediGray,
          backgroundColor: "transparent",
          borderColor: colors.mediMagenta,
          borderWidth: 2,
          border: "solid",
          borderBottomWidth: 0,
        },
        "&.MuiTab-textColorInherit.Mui-disabled": {
          backgroundColor: "transparent",
          opacity: 1,
          minWidth: "100%",
        },
      }
    },
  },
});

export default theme;