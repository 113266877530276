



interface IMenuRoute {
  name: string;
  target: string;
}

 const MenuRoutes = (props) => {

     var routes: Array<IMenuRoute> = [
         { name: props.t("navbar_overview"), target: "/home" }
     ];
    return routes
}

export default MenuRoutes;
