import { Container } from "reactstrap";
import { getConfig } from "services/config.service";
import { FlexBox, Left, Right } from "shared/shared";
import colors from "shared/style/colors";
import styled from "styled-components";
import theme from "../../style/theme";
import { version } from "autobuild_version";

const FooterArea = styled.div`
  background-color: ${theme.colors.inactive};
  flex-shrink: 0;
`;

const Slogan = styled.div`
  color: ${theme.colors.active};
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentBadge = styled.span`
  color: ${colors.mediGray};
`;

const SwaggerLink = styled.a`
  color: ${colors.mediGray};
  margin-right: 10px;
  :hover,
    :focus,
    :active,
    :visited {
      text-decoration: none;
      color: ${theme.colors.active};
    };
    color: ${theme.colors.active};
`;

export const FooterComponent = () => {
  const config = getConfig();

  return (
    <FooterArea>
      <Container>
        <FlexBox>
          <Left>
            <Slogan>medi. ich fühl mich besser.</Slogan>
          </Left>
          <Right>
            {config.showSwaggerLinks ? <>
              <SwaggerLink href="/swagger-private" target="_blank">Private API</SwaggerLink>{" "}
              <SwaggerLink href="/swagger-public" target="_blank">Public API</SwaggerLink>
            </> : <></>}
             <span><EnvironmentBadge>{config.name} {version}</EnvironmentBadge></span>
          </Right>
        </FlexBox>
      </Container>
    </FooterArea>
  );
};
