import styled from 'styled-components';
import { LoadingIndicatorComponent } from '../loading_indicator/loading_indicator.component';
import * as style from "./loading_overlay.style";

export const LoadingOverlayComponent = () => {
    return (
        <style.OuterWrapper>
            <style.InnerWrapper>
                <LoadingIndicatorComponent />
            </style.InnerWrapper>
        </style.OuterWrapper>);
}

export const LoadingOverlayContainer = styled.div`
    position: relative;
  `;