import { ErrorBoundary } from "react-error-boundary";
import { ErrorPanelComponent } from "./error_panel.component";

function FallbackComponent({ error }) {
  return (
    <ErrorPanelComponent title="Unerwarter Fehler">
      <p>Es trat ein unerwarter Fehler auf.</p>
      <pre>Fehlertext: {error.message}</pre>
      <em>
        Sollte der Fehler bestehen bleiben, melden Sie sich bitte bei Ihrem
        Administrator.
      </em>
    </ErrorPanelComponent>
  );
}

export type ErrorHandlerComponentProps = {
  children: React.ReactNode
}

export const ErrorHandlerComponent: React.FC<ErrorHandlerComponentProps> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  );
};
