import React from "react";
import { Container } from "reactstrap";
import * as style from "./breadcrumbs_root.style";
import { HomeBreadcrumbs } from '../../home/home.breadcrumbs';



export const BreadcrumbsRootComponent = () => {

  return (
    <style.BreadcrumbArea>
      <Container>
        <HomeBreadcrumbs />
      </Container>
    </style.BreadcrumbArea>
  );
};
