import { SearchValue } from "models/productmaster/SearchCriteriaModel";
import { IProductPropertyNodeModel } from "../../../models/selectproductmanager/setproducts/productPropertyNodeModel";
import { ProductTypeEnum, ProductValues } from "../../../models/selectproductmanager/setproducts/productSelection_Type_Enum";
import { from } from "linq-to-typescript"

export const findNodeByIdMethode = (parentNode: IProductPropertyNodeModel, nodeId: string) => {

    if (parentNode.nodeId === nodeId)
        return parentNode;

    if (parentNode.children) {

        for (let child of parentNode.children) {
            let found = findNodeByIdMethode(child, nodeId)
            if (found)
                return found;
        }
    }
};

export const findNodeParentByIdMethode = (parentNode: IProductPropertyNodeModel, nodeId: string, parentId: string) => {

    if (parentNode.nodeId === nodeId && parentNode.parentNodeId === parentId)
        return parentNode;

    if (parentNode.children) {

        for (let child of parentNode.children) {
            let found = findNodeParentByIdMethode(child, nodeId, parentId)
            if (found)
                return found;
        }
    }
};


export const findNodeParentByUnqiueKey = (parentNode: IProductPropertyNodeModel, nodeUniquekey: string) => {

    if (parentNode.uniqueKey === nodeUniquekey)
        return parentNode;

    if (parentNode.children) {

        for (let child of parentNode.children) {
            let found = findNodeParentByUnqiueKey(child, nodeUniquekey)
            if (found)
                return found;
        }
    }
};

export const determineSearchCriterion = (lastNodeArray: IProductPropertyNodeModel[], searchValues: string[]) => {
    let saveFoundItems: IProductPropertyNodeModel[] = [];

    try {
        lastNodeArray.forEach((item) => {

            var foundFirstSection: IProductPropertyNodeModel = null;
            var foundAfterSecondSection: IProductPropertyNodeModel = null;

            let number = 0;
            for (let searchValue of searchValues) {

                if (foundFirstSection !==null && foundAfterSecondSection !== undefined) {
                    foundAfterSecondSection = findNodeParentByIdMethode(foundFirstSection, searchValue, number === 0 ? '' : searchValues[number-1]);
                    if (foundAfterSecondSection) {
                        number++;
                        saveFoundItems.push(foundAfterSecondSection);

                        if (saveFoundItems.length === searchValues.length) {
                            break;
                        }
                    } else {
                        saveFoundItems = []
                        foundAfterSecondSection = null;
                    }
                } else {
                    foundFirstSection = findNodeParentByIdMethode(item, searchValue, number === 0 ? '': searchValues[number]);
                    if (foundFirstSection) {
                        number++;
                        saveFoundItems.push(foundFirstSection);
                    } 
                }

                if (saveFoundItems.length === 0) {
                    break;
                }
            }
        });

    } catch (error) {

    }
    return saveFoundItems;
}

export const determineParentNodeIds =  (uniqueKey: string) => {

    let nodeIds = uniqueKey.split('/');
    nodeIds.pop();
    return nodeIds.length === 0 ? null : nodeIds;
}


export const dataCheckDisplayCircularProgress = (propertySearchcriterion: IProductPropertyNodeModel[], node: IProductPropertyNodeModel, ispending : boolean) => {

    const maxArraylength = propertySearchcriterion.length;
    let status = false;
    if (ispending === true) {

        if (maxArraylength > 0) {

            const result = propertySearchcriterion[maxArraylength - 1].uniqueKey.match(node.uniqueKey);


            if (result != null) {

                if (result[0].length === propertySearchcriterion[maxArraylength - 1].uniqueKey.length) {
                    status = true;
                    console.log(status);
                    return status;
                } else {
                    return status
                }
            } else {
                return status;
            }
        } else {

            return status;
        }
    } else {
        
        return status
    }
}

export const setSearchCriterionArray =  (searchCriterion: string) => {

    let nodes: string[] = [];
    let propertyNodeModel: IProductPropertyNodeModel[] = []

    if (searchCriterion.includes('/')) {
        nodes = searchCriterion.split('/');

        nodes.forEach(async (nodeValue) => {
            propertyNodeModel.push(setProductNodeModel(nodeValue));
        });

    } else {
        nodes.push(searchCriterion);
        propertyNodeModel.push(setProductNodeModel(searchCriterion));
    }

    return propertyNodeModel;
}

const getEnumMethode = (nodeId: string) =>  {

    let number = 0;

    for (let i of ProductValues) {

        if (i.text === nodeId) {

            number = i.value;
            break ;
        }
    }

    if (number === 0) {
        console.log(number,'error !! productenum does not include this nodeID ')
    }

    return number; 
}

const setProductNodeModel = (nodeValue: string) => {

    try {
        let itemSplit = nodeValue.split('_');
        let productTypeEnum = getEnumMethode(itemSplit[0]);
        let value = itemSplit[1] === "null" ? null : itemSplit[1];
        let propertyNodeModel: IProductPropertyNodeModel = { nodeId: itemSplit[0], value: value, uniqueKey: '', children: [], hasChildren: false, parentNodeId: '', text: nodeValue, type: productTypeEnum, copyOldProperty: false };

        return propertyNodeModel;
    } catch {
        console.log('error')
    }
}

export const createSearchCriteriaText = (propertySearchcriterion: IProductPropertyNodeModel[], sku: string) => {

    let criteriaTexts : string[] = [];
    propertySearchcriterion.forEach((e) => {
        criteriaTexts.push(e.text);
    });

    if (sku)
        criteriaTexts.push(sku);

    return criteriaTexts.join(' + ');
}  

export const removeSkuData = (currentSearchValues : SearchValue[], currentProductPropertyModels: IProductPropertyNodeModel[]) =>
{
    const searchValues = from(currentSearchValues);
    const skuCriterion = searchValues.lastOrDefault(c => c.type === ProductTypeEnum.Sku);

    if(skuCriterion){
        if (skuCriterion === searchValues.last())
            currentSearchValues.pop();      
    }      

    const productNodesModels = from(currentProductPropertyModels);
    const skuProductNodeModel = productNodesModels.lastOrDefault(n => n.type === ProductTypeEnum.Sku);

    if(skuProductNodeModel){
        if (skuProductNodeModel === productNodesModels.last())            
            currentProductPropertyModels.pop();
    }

    return {currentSearchValues, currentProductPropertyModels};
}