import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import HierarchyAssignment from "../../../services/hierarchyAssiment.service";
import ProductmasterService from "services/productmaster.service";
import { logicToggle } from './hierarchyAssignment.slice';
import { IUniqueKeyAndPageNumber } from '../../../models/productmastertreeview/IUniqueKeyAndPageNumber';

export const getPropertyNodesComponent = createAsyncThunk(
    'productmastertreeview/ProductManagerTreeView/getpropertynodes',
    async ( data:IUniqueKeyAndPageNumber, { getState, dispatch}) => {
        
        if (data) {
            await dispatch(logicToggle({ unique: data.unique, paging: data.paging }));
            
        }
        const state = getState() as RootState;
        const response = await HierarchyAssignment.getPropertyNodes(state.selectTreeView.dataSku.hierarchyProperty);
        handleResponseErrors(response, "getPropertyNodes");
        return response;
    }
);



export const DeleteSearchCriteria = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteria',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.GetProductmaster(state.selectTreeView.data.SearchCriteria);
        handleResponseErrors(response, "articletunks");
        return response;
    }
);

export const ResultpaginatedSkuList = createAsyncThunk(
    'articletunks/articletunks/PostpaginatedSkuList',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        const response = await HierarchyAssignment.paginatedSku(state.selectTreeView.dataSku.determine);
        handleResponseErrors(response, "paginatedSkuList");
        return response;
    }
);
