
import { IProductmaster, IProductmasterAdd } from "models/productmastertable/productmastertable.model";
import { ISelectionProductmaster } from "../../../../models/productmastertable/productmastertableselection.model";
export const checkIfAbbrevationIsUsed = (productmaster: IProductmaster[], abbreviation: string) => {
    let status: boolean = false;
    productmaster.forEach((entry) => {
        if (entry.abbreviation === abbreviation) {
            status = true;
        }
    });

    return status;
}


export const checkIfTakeOverIsUsed = (takeOverStatus: boolean, productmaster: IProductmasterAdd, selection: ISelectionProductmaster) => {

    let status = false;

    if (takeOverStatus) {

        if (productmaster.abbreviation.length > 0 && productmaster.name.length > 0 && selection.index>0) {

            status = true;
        }
    } else {

        if (productmaster.abbreviation.length > 0 && productmaster.name.length > 0) {

            status = true;
        }
        
    }

    return status;
}