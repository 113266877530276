import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import PreSelectionService from 'services/preselection.service';
import { GetSelectedItem } from './preselection.reducer';


export const selectProducts = createAsyncThunk(
    'productmaster/productmaster/selectProducts',
    async (_, { getState }) => {


        const state = getState() as RootState
        const selectedItems = GetSelectedItem(state.selectproduct.data.selectPreSelection);
        const response = await PreSelectionService.selectProducts(selectedItems);
        handleResponseErrors(response, "productmanager");
        return response;
    }
)

export const loadPreselectionApi = createAsyncThunk(
    'productmaster/productmaster/loadPreselection',
    async (_,) => {
        const response = await PreSelectionService.loadPreselectionId(null);
        handleResponseErrors(response, "productmanager");
        return response;
    }
)

export const initComponent = createAsyncThunk(
    'productmaster/productmaster/initComponent',
    async (_, { getState, dispatch }) => {
        const response = await PreSelectionService.loadAdditionalSelection();
        handleResponseErrors(response, "productcategory");
        return response;
    }
)


export const getPreSelection = createAsyncThunk(
    'productmaster/productmaster/getPreselection',
    async (_, { getState, dispatch }) => {
    const response = await PreSelectionService.getSelection();
    handleResponseErrors(response, "productmanager")
    return response;
    }
)


