import { ProductManagerViewEdit } from "./productmasterAdd.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCountry, setpossibleSelection } from "./productmasterAdd.thunks"
import { ISelectionProductmaster } from "../../../../models/productmastertable/productmastertableselection.model";
import { initComponentPm } from "../../redux/productmaster.thunks";
import { IProductmaster } from "../../../../models/productmastertable/productmastertable.model";
import { checkIfTakeOverIsUsed } from "../component/productmasterAdd.reducer";

const initialState: ProductManagerViewEdit = {
    loadedData: {
        productmaster: [],
       possibleProductmaster:[]
    },
    command: {
        AddEditProductMaster: { status: "idle", canExecute: false },
        AddProductMaster: { status: "idle", canExecute: false }
    },
    data: {
        productmaster: { 
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            takeOverFrom: false
        },
        selectedproductmaster: {
            index:0,
            description:""
        },
       
        checkboxvalue: false,
        errortextbox: false,
        requirefield: false,
        sameabbreviation: false,
        isAbbrevationToLong: false,

    }
}

export const productMasterAddSlice = createSlice({
    name: 'getTreeView',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        setAbbreviation: (state, action: PayloadAction<string>) => {
            let newAbbrevation = action.payload.toUpperCase();

            if (newAbbrevation.length < 31) {
                state.data.isAbbrevationToLong = false;
                state.data.productmaster.abbreviation = newAbbrevation;
                state.data.requirefield = checkIfTakeOverIsUsed(state.data.productmaster.takeOverFrom, state.data.productmaster, state.data.selectedproductmaster);
            } else {
                state.data.isAbbrevationToLong = true;
                state.data.requirefield = false;
            }
        },
        setDescription:(state, action: PayloadAction<string>) =>{
            state.data.productmaster.name = action.payload;

            if (state.data.isAbbrevationToLong ===false) {
                state.data.requirefield = checkIfTakeOverIsUsed(state.data.productmaster.takeOverFrom, state.data.productmaster, state.data.selectedproductmaster);
            }
           
        },
        setLongDescription: (state, action: PayloadAction<string>) => {
            state.data.productmaster.description = action.payload;
        },
        checkAbbreviation: (state, action: PayloadAction<IProductmaster[]>)=>{
            
            let productmaster = action.payload;
            let status: boolean = false;
            productmaster.forEach((entry) => {
                if (entry.abbreviation === state.data.productmaster.abbreviation) {
                    status = true;
                }
            });

            state.data.sameabbreviation = status;
        },
        updateSelection: (state, action: PayloadAction<ISelectionProductmaster>) => {
            state.data.selectedproductmaster = action.payload;
            state.data.productmaster.id = state.data.selectedproductmaster.index
            state.data.productmaster.takeOverFrom = state.data.checkboxvalue;
            if (state.data.isAbbrevationToLong === false) {
                state.data.requirefield = checkIfTakeOverIsUsed(state.data.productmaster.takeOverFrom, state.data.productmaster, state.data.selectedproductmaster);
            }
           
        },
        setErrorTextField: (state, action: PayloadAction<boolean>) => {
            state.data.errortextbox = action.payload;
            state.command.AddProductMaster.status="idle"

        },
        confirmDelete: (state) => {
            state.command.AddProductMaster.status = "idle";
            state.command.AddEditProductMaster.status = "idle"
        },
        setDynamicCheckbox:(state, action: PayloadAction<boolean>)=> {
            

            state.data.productmaster.isCheckbox = action.payload;
        },
        setTakeOverFromCheckbox: (state, action: PayloadAction<boolean>) => {
           
            state.data.checkboxvalue = action.payload;


            if (state.data.checkboxvalue === true) {

                if (state.loadedData.possibleProductmaster.length === 0) {

                    state.loadedData.possibleProductmaster = setpossibleSelection
                        (
                            state.loadedData.possibleProductmaster,
                            state.loadedData.productmaster
                        );
                }
            } 

            state.data.productmaster.takeOverFrom = state.data.checkboxvalue;
            state.data.requirefield = checkIfTakeOverIsUsed(state.data.productmaster.takeOverFrom, state.data.productmaster, state.data.selectedproductmaster);
        },
        setRequireToFalseAdd: (state) => {

            state.data.requirefield = false;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(initComponentPm.pending, (state) => {
           state.command.AddEditProductMaster.status = "pending"
           state.command.AddEditProductMaster.canExecute = false;
        }).addCase(initComponentPm.rejected, (state, action) => {
           state.command.AddEditProductMaster.status = "error"
           state.command.AddEditProductMaster.message = action.error.message;
           state.command.AddEditProductMaster.canExecute = true;
        }).addCase(initComponentPm.fulfilled, (state, action) => {
           state.command.AddEditProductMaster.status = "success"
            state.command.AddEditProductMaster.canExecute = true;
            state.loadedData.productmaster = action.payload.getData();
        })

        builder.addCase(addCountry.pending, (state) => {
            state.command.AddProductMaster.status = "pending"
            state.command.AddProductMaster.canExecute = false;
        }).addCase(addCountry.rejected, (state, action) => {
            state.command.AddProductMaster.status = "error"
            state.command.AddProductMaster.message = action.error.message;
            state.command.AddProductMaster.canExecute = true;
        }).addCase(addCountry.fulfilled, (state, action) => {
            state.command.AddProductMaster.status = "success"
            state.command.AddProductMaster.canExecute = true;
        })
    }
})

export const {
    resetState,
    setAbbreviation,
    setDescription,
    setLongDescription,
    updateSelection,
    setDynamicCheckbox,
    setErrorTextField,
    confirmDelete,
    checkAbbreviation,
    setTakeOverFromCheckbox,
    setRequireToFalseAdd
}= productMasterAddSlice.actions

export default productMasterAddSlice.reducer



