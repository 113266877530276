import { PrimaryButtonComponent } from "../../shared/shared";
import { Container } from "reactstrap";

import { publicClientApplication } from "../../services/aad.service";

const UnauthorizedComponent = () => {
  const accounts = publicClientApplication.getAllAccounts();
  const account = accounts[0];
  

  var showAccountInfo = false;
  var accountInfoBox = <></>;

  if (account != null) {
    showAccountInfo = true;
    accountInfoBox = (
      <div>
        <ul>
          <li>User: {account.name}</li>
          <li>UserName: {account.username}</li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <h1>Kein Zugriff</h1>
        <p>
          Sie besitzen nicht die notwendigen Rechte, um auf diese Seite
          zuzugreifen
        </p>

        {showAccountInfo ? (
          <>
            <PrimaryButtonComponent onClick={() => publicClientApplication.logoutRedirect()}>
              Abmelden und mit anderem Konto anmelden
            </PrimaryButtonComponent>
            <h2>Account:</h2>
            {accountInfoBox}
          </>
        ) : (
            <></>
          )}
      </Container>
    </div>
  );
};

export default UnauthorizedComponent;
