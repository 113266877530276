import styled from "styled-components";
import theme from "../../style/theme";

export const Box = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid ${theme.colors.inactive};
  color: ${theme.colors.inactive};
  padding: 5px;
`;
export const SearchButton = styled.div`
  margin-left: auto;
`;

export const TextField = styled.input`
  background:transparent;
  border: none;
  outline: none;
  :focus {
    border: none;
    outline: none;
  }
`;

interface SearchInfoIconProps {
  active: boolean;
}

export const SearchInfoIcon = styled.span<SearchInfoIconProps>`
  color: ${(props) =>
    props.active ? theme.colors.text : theme.colors.inactive};
`;