
import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import TextField from '@material-ui/core/TextField';
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent } from 'shared/shared';
import SaveIcon from '@material-ui/icons/Save';
import CancleIcon from '@material-ui/icons/Cancel';
import { useNavigate } from 'react-router-dom';
import { setDescriptionUpdate, setDynamicCheckbox, setLongDescriptionUpdate, setRequireFieldToFalseUpdate } from '../redux/productmasterUpdate.slice';
import { updateProductMaster } from '../redux/productmasterUpdate.thunks';
import { DialogWindowOverviewComponent } from '../Dialog/dialogUpdateWindowOverview';
import { setErrorTextField } from '../redux/productmasterUpdate.slice';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';


export const ProductmanagerCountryTableUpdateComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.dataGridUpdate);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const setErrorTextFieldStatus = () => {
        if (state.data.errortextbox === false) {
            dispatch(setErrorTextField(true));
        }
    };

    const jumpToMainTable = async () => {
        await dispatch(updateProductMaster());
        navigate("/home/productmaster");

    }


    const CancelButton = async () => {
        await dispatch(setRequireFieldToFalseUpdate());
        await navigate("/home/productmaster");
    }

    if (state.command.UpdateDbProductmaster.status === "pending") {
        return <LoadingIndicatorComponent />
    }

    //if (state.command.UpdateDbProductmaster.status === "success") {
    //    enqueueSnackbar(t("status_label_success"), { variant: "success" });
    //}
    if (state.command.UpdateDbProductmaster.status === "error") {
        setErrorTextFieldStatus();
    }



    return (

        <div>
            {state.data.errortextbox ? <DialogWindowOverviewComponent /> : <></>}
            


            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" label={t("label_abbreviation")} variant="outlined" value={state.data.productmaster.abbreviation} disabled={ true} />
            </div>
            <br />
            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" label={t("label_definition")} variant="outlined" onChange={(e) => dispatch(setDescriptionUpdate(e.target.value))} required value={state.data.productmaster.name} />
            </div>

            <br />

            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" variant="outlined" onChange={(e) => dispatch(setLongDescriptionUpdate(e.target.value))} value={state.data.productmaster.description} label={t("label_longdefinition")} />
            </div>
            <br />
            
        
                <FormControlLabel control={<Checkbox onChange={(event, checked) => dispatch(setDynamicCheckbox(checked))} checked={state.data.productmaster.dynamic} value={state.data.productmaster.dynamic } />} label={t("checkbox_configuration_dynamicproductmaster")} />
            


            <br/>
            <FlexBox>
                <Left>
                    <PrimaryButtonComponent onClick={() => jumpToMainTable()}  disabled={!state.data.requirefield }>   
                        <SaveIcon /> {t("button_confirmation")}
                    </PrimaryButtonComponent>
                    <PrimaryButtonComponent onClick={() => CancelButton()} >
                        <CancleIcon /> {t("button_cancle")}
                    </PrimaryButtonComponent>

                </Left>
            </FlexBox>
        </div>
    );

};