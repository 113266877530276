import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import WorkerQueueService from "services/workerqueue.service";
import { handleResponseErrors } from "../../../shared/networking/error_handling";



export const getWorkerQueuedata = createAsyncThunk(
    'productmaster/Workerqueue/getallActiveEntrys',
    async (_) => {
        const response = await WorkerQueueService.getAllActiveWorkerQueue();
        handleResponseErrors(response, "get current Workerqueue");
        return response;
    }
);


export const getWorkerQueueOpenJobNumber = createAsyncThunk(
    'productmaster/Workerqueue/getworkerQueueOpenJobNumber',
    async (_) => {
        const response = await WorkerQueueService.getWorkerQueueOpenJobNumber();
        handleResponseErrors(response, "get current Workerqueue number");
        return response;
    }
);

export const postNewManuelleWorkerQueue = createAsyncThunk(
    'productmaster/Workerqueue/addNewEntry',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await WorkerQueueService.addNewWorkerQueue(state.workerQueue.data.workerQueue);
        handleResponseErrors(response, "Add a new WorkerQueue");
        return response;
    }
);

export const testingAs400 = createAsyncThunk(
    'productmaster/as400',
    async (_,) => {       
        const response = await WorkerQueueService.getStatusFromAs400();
        handleResponseErrors(response, "Add a new WorkerQueue");
        return response;
    }
);

export const deleteWorkerQueue = createAsyncThunk(
    'productmaster/Workerqueue/delete',
    async (id: number) => {
        const response = await WorkerQueueService.deleteWorkerQueue(id);
        handleResponseErrors(response, "Delete a new WorkerQueue");
        return response;
    }
);

export const restartWorkerQueue = createAsyncThunk(
    'productmaster/Workerqueue/restart',
    async (id: number) => {
        const response = await WorkerQueueService.restartWorkerQueue(id);
        handleResponseErrors(response, "Restart a new WorkerQueue");
        return response;
    }
);