import { useNavigate } from 'react-router-dom';
import { ClickAwayListener, Menu, MenuItem,  Popper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as style from './productmaster.style'
import { MenuOverviewComponent } from 'shared/components/overview/menu_overview.component';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { useEffect, useState } from "react";
import { AppDispatch } from 'app/redux_store';
import {  filterpoints, resetState, setDeleteProductmasterToFalse } from '../redux/productmaster.slice'
import { setRequireFieldToFalseUpdate, updateProduct} from '../Update/redux/productmasterUpdate.slice'
import { initComponentPm, updateProductMasterTable } from '../redux/productmaster.thunks';
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from 'shared/shared';
import AddIcon from '@material-ui/icons/Add';
import RoleBackIcon from '@material-ui/icons/NavigateBefore'
import { IProductmaster } from "models/productmastertable/productmastertable.model";
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getProduct } from '../Delete/redux/productmasterDelete.slice';
import { DialogWindowOverviewComponent } from '../Delete/Dialog/dialogwindowoverview';
import { PublishedIconComponent } from '../../../shared/components/icons/published_icon.component';
import './popover.css';
import { InfoIconStyled } from './productmaster.style';
import { Grid } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';
import { setRequireToFalseAdd } from '../Add/redux/productmasterAdd.slice';
export const ProductmasterTableOverview = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.dataGrid);
    const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [selectedProducttunks, setselectedProducttunks] = useState<IProductmaster>(null);
    var pointsTable = <></>;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [dialoge, setdialoge] = useState(false);
    const stateEdit = useAppSelector((state) => state.dataGridEdit);
    const stateUpdate = useAppSelector((state) => state.dataGridUpdate);
    const msVisibleSnackbar: number = 11000;
    const [spopover, setspopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const[popoverId, setPopoverId] = useState<number | null>(null);

    useEffect(() => {
        return () => {
            dispatch(resetState()) //reset
            console.log("send resquest to redux")
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(initComponentPm());
    }, [dispatch]);

    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>, id: number) => {

        if (spopover === false) {
            setAnchorEl(event.currentTarget);
            setspopover(true);
            setPopoverId(id);
        }
    };

    const setHoverText = (selectedValue: string, rows: IProductmaster[], id: number) => {
        if (selectedValue !== null && selectedValue !== undefined) {
            
            let value = rows.findIndex((item) => item.name === selectedValue);
            if (value !== null && value >= 0) {
                if (rows[value].description === null) {
                    return 'There is no hover';
                } else {
                    return rows[value].description;
                }
            }
        }
    }

    const handleClickAway = () => {
        setspopover(false);
        setAnchorEl(null);
        setPopoverId(null);
    };

    const onMenuClose = () => {
        setMenuAnchor(null);
    };

    const onMenuOpen = (event, articleitem: IProductmaster) => {
        setselectedProducttunks(articleitem);
        setMenuAnchor(event.currentTarget);
        
    };

    const handleDelete = () => {
        if (dialoge) {
            setdialoge(false);
        } else {
            setdialoge(true);
        }

        dispatch(getProduct(selectedProducttunks));
    }

    const handleUpdate = () => {
        onMenuClose();
        dispatch(updateProduct(selectedProducttunks))
        dispatch(updateProductMasterTable);
        navigate("/home/productmaster/Update")
    }

    const showPopUp = (item: IProductmaster) => {
        return (

            <style.DescriptionComponent>
                    <style.DescriptionTooltip>
        
            <Popper
            style={{zIndex:9999}}
                open={popoverId === item.id && spopover}
                anchorEl={anchorEl}
                placement="top"
                       
            >
                
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <style.BoxStyled>
                            <Grid container>
                                <Grid container item justifyContent="flex-end">
                                        <CloseIcon htmlColor={ '#fff'} onClick={handleClickAway}></CloseIcon>
                                </Grid>
                                <Grid item >
                                    {setHoverText(item.name, state.loadedData.articletabledata, item.id)}
                                </Grid>
                            </Grid>
                        </style.BoxStyled>
                        </ClickAwayListener>
                   
            </Popper>
            </style.DescriptionTooltip>
                </style.DescriptionComponent>
        );
    }

    if (state.command.getProductMasterTable.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.getProductMasterTable.status === "success" && stateUpdate.data.requirefield === true) {
        enqueueSnackbar(t("status_label_success"), { variant: "success", autoHideDuration: msVisibleSnackbar });
        dispatch(setRequireFieldToFalseUpdate());
    }

    if (state.command.getProductMasterTable.status === "success" && stateEdit.data.requirefield === true) {
        enqueueSnackbar(t("status_label_success"), { variant: "success", autoHideDuration: msVisibleSnackbar });
        dispatch(setRequireToFalseAdd());
    }

    if (state.command.getProductMasterTable.status === "success" && state.deleteAProductmaster === true)
    {
        enqueueSnackbar(t("status_label_success"), { variant: "success", autoHideDuration: msVisibleSnackbar });
        dispatch(setDeleteProductmasterToFalse());
    }

    if (state.command.getProductMasterTable.status === "error" && stateUpdate.data.requirefield === true) {
        enqueueSnackbar(t("status_label_error"), { variant: "error", autoHideDuration: msVisibleSnackbar });
        dispatch(setRequireFieldToFalseUpdate());
    }

    if (state.command.getProductMasterTable.status === "error" && state.deleteAProductmaster === true) {
        enqueueSnackbar(t("status_label_error"), { variant: "error", autoHideDuration: msVisibleSnackbar });
        dispatch(setDeleteProductmasterToFalse());
    }

    if (stateEdit.command.AddProductMaster.status === "error" && stateEdit.data.requirefield === true) {
        enqueueSnackbar(t("status_label_error"), { variant: "error" });
    }

    if (state.loadedData.articletabledata.length === 0) {
        pointsTable = <div>{t("empty_table")}</div>
    }

    return (
        <div>
            <FlexBox>
                <Left>
                    <SearchFieldComponent placeholder={t("label_searchbar")} onSearch={(filterText) => dispatch(filterpoints(filterText))} />
                    
                    
                </Left>
                <Right>
                    <PrimaryButtonComponent onClick={() => navigate("/home/productmanagerpreselection")}>
                        <RoleBackIcon /> {t("button_goto_productmanager")}
                    </PrimaryButtonComponent>
                    <PrimaryButtonComponent onClick={() => navigate("/home/productmaster/Add")} >
                        <AddIcon /> {t("button_addnew_master")}
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <br />
            <br />
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={onMenuClose}
            >
                <MenuItem onClick={handleUpdate} >{t("headline_label_productmastertable_rename")}</MenuItem>
                <MenuItem onClick={handleDelete}>
                    {t("button_remove_master")}

                    {dialoge ? <DialogWindowOverviewComponent /> : <></>}
                </MenuItem>

            </Menu>


            <TableContainer>
                <Table className="testtable" aria-label="massmask table">
                    <TableHead>
                        <TableRow>

                            <style.StyledMenuHeaderCell align="left">

                            </style.StyledMenuHeaderCell>

                            <style.StyledHeaderCell align="left">{t("table_headline_abbreviation")}</style.StyledHeaderCell>
                            <style.StyledHeaderCell align="left">{t("table_headline_definiton")}</style.StyledHeaderCell>
                            <style.StyledHeaderCell align="left">{t("table_headline_dynamicmaster")} </style.StyledHeaderCell>
                            <style.StyledHeaderCell align="left">{t("table_headline_searchcriteria")} </style.StyledHeaderCell>
                        </TableRow>
                    </TableHead>
                    {state.loadedData.articletabledata.map((e) =>
                        <TableBody key={e.id}>
                            <style.StyledTableRow key={e.id} hover={true}>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    <MenuOverviewComponent onMenuOpen={onMenuOpen}
                                        menuItem={e} key={e.id} />
                                </style.StyledMenuTableCell>

                                <style.StyledMenuTableCell align="left" size='medium'  >
                                    {e.abbreviation}
                                </style.StyledMenuTableCell>

                                <style.StyledMenuTableCell key={e.id} align="left" size='medium' >
                                    {e.description ? (
                                        <>
                                           {e.name}
                                            <InfoIconStyled onClick={(ic) => handlePopoverOpen(ic, e.id)} />

                                        </>
                                    ) : (
                                            <> {e.name}</>
                                            
                                    )}

                                    {showPopUp(e)}
                                    
                                </style.StyledMenuTableCell>

                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.dynamic === true ? <PublishedIconComponent /> : ""}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.searchCriteria}
                                </style.StyledMenuTableCell>

                            </style.StyledTableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            <TableContainer>{pointsTable}</TableContainer>
        </div>
    );
};
