import { LoadingStatus } from '../../models/loading_state';
export class ApiResult<T> {
    private statusCode: Number;
    private isRequestTimeout: boolean;
    private data: T;

    public withTimeout(): ApiResult<T> {
        this.isRequestTimeout = true;
        return this;
    }

    public withStatusCode(statusCode: Number): ApiResult<T> {
        this.statusCode = statusCode;
        return this;
    }

    public withData(data: T): ApiResult<T> {
        this.data = data;
        return this;
    }

    public isSuccessfull() {
        return !this.isRequestTimeout && this.statusCode >= 200 && this.statusCode < 300;
    }

    public isConflict() {
        return this.statusCode === 409
    }

    public isUnprocessableEntity() {
        return this.statusCode === 422;
    }

    public isNotFound() {
        return this.statusCode === 404
    }

    public isBadRequest() {
        return this.statusCode === 400
    }

    public isServerError() {
        return this.statusCode === 500
    }

    public isTimeout() {
        return this.isRequestTimeout;
    }

    public getData() {
        return this.data
    }

    public getErrorCode(): string
    {
        return this.statusCode.toString();
    }

    public state(): LoadingStatus {
        if (this.isTimeout()
            || this.isServerError()
            || this.isBadRequest()
            || this.isNotFound()
            || this.isUnprocessableEntity()
            || this.isConflict()) {

            return "error";
        }
        return "success";
    }
}