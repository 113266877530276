import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useAppDispatch } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { setErrorTextField } from '../redux/productmasterAdd.slice';
export const DialogWindowOverviewComponent = () => {
    const [open, setOpen] = React.useState(true);
    const dispatch: AppDispatch = useAppDispatch();
  
    const handleClose = async () => {
       await setOpen(false);
       await dispatch(setErrorTextField(false));
        
    };
    return (
        <div>

            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ein Fehler ist Aufgereten"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         ° Das eindeutige Kürzel wird schon verwendet
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Akzeptieren
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}