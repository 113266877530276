import { ThumbnailModel } from "../models/thumbnail.model";
import { ThumbnailComponent } from "../shared/components/buttons/thumbnail.component";
import preselection from './images/preselection.jpg';
import productmaster from './images/productmaster.png';
import { useTranslation } from 'react-i18next';
import * as style from "./home.style";


export const HomeComponent = () => {
    const { t } = useTranslation();
    const thumbnails: ThumbnailModel[] = [
       
        new ThumbnailModel(t("label_shortcut_productmanager"), preselection, "/home/productmanagerpreselection"),
        new ThumbnailModel(t("label_shortcut_productmaster"), productmaster, "/home/productmaster"),
        new ThumbnailModel(t("label_shortcut_Administrator"), productmaster, "/home/export"),
    ];

    return (
        <>
            <style.ThumbnailWrapper>
                {thumbnails.map((thumb, i) => {
                    return (<ThumbnailComponent key={i} thumbnail={thumb} />)
                })}
            </style.ThumbnailWrapper>
        </>
    );
}