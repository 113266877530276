import { HierarchyAssignmentOverviewState } from "./hierarchyAssignment.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  getPropertyNodesComponent, ResultpaginatedSkuList } from "./hierarchyAssignment.thunks";
import { DeterminePaginatedSkuParameter, IProductPropertyNodeModel, ISkuCheckboxData } from "../../../models/selectproductmanager/setproducts/productPropertyNodeModel";
import {  enableMapSet } from 'immer';
import { determineSearchCriterion, determineParentNodeIds, findNodeByIdMethode, findNodeParentByUnqiueKey, setSearchCriterionArray, createSearchCriteriaText, removeSkuData } from "./hierarchyAssignment.reducer";
import { ISearchCriterion } from "../../../models/productmaster/SearchCriteriaModel"
import { ProductTypeEnum } from "models/selectproductmanager/setproducts/productSelection_Type_Enum";
import { IUniqueKeyAndPageNumber } from "models/productmastertreeview/IUniqueKeyAndPageNumber";
import { IUniqueKeyAndPreselectionNumber } from "../../../models/productmastertreeview/IUniqueKeyAndPreselectionNumber";
import { setExpandValue } from "../component/hierarchyAssigmentreducer";


enableMapSet();
const initialState: HierarchyAssignmentOverviewState = {
    loadedData: {
        isinitalising: true,
        productChildren: [],
        productChildrenWithDataGridID: {
            nodes: [],
            tableid: 0,
        }
    },
    command: {
        childrenRequest: { status: "idle", canExecute: false },
    },
    data: {
        propertyChildren: [],
        propertyChildrenOld: [],
        copyoldPropertyflag: false,
        areChildrenReady: false,
        sortedPropertyHierarchyAssigment: [],
        propertySearchcriterion: [],

        expandedHierarchyAssigment: {
            expandedNodes: [],
        },

        wasChildrenAlreadyLoaded: false,
        preSelectionLength: null,
        HierarchyAssignmentTitle: "",
        SearchCriteria: {
            tableId: 0,
            productNodesModel: []
        },
        SearchCriteriaSku: {
            tableId: 0,
            productNodesModel: [],
            sku: "",
            isProductList: false,
        },
        SearchCriteriaQuery: {
            criterion: {
                searchValues: []
            },
            productNodesModel: [],
            sideTable: false,
            preSelectionId: 0,
        },
        showTable: false,
        isHierarchyPending: false,
        dataCheckDisplayCircularProgress: false,
        isNodeAlreadyClicked: false,
        headlineCompositionOfSearchCriteria: "",

    }, dataSku: {
        showProductlist: false,
        skuList: [],
        skuListOld: [],
        sku: "",
        SearchCriteriaQuery: {
            criterion: {
                searchValues: []
            },
            productNodesModel: [],
            sideTable: false,
            preSelectionId: 0,
        },
        visibleProductListButton: true,
        sortedPropertyHierarchyAssigmentSku: [],
        filterText: { text: "", nodes: [] },
        showSkuTable: false,
        showLastSelection: [],
        hierarchyProperty: {
            id: 0,
            sortedPropertyHierarchyAssigment: []
        },
        determine: {
            //page: 0,
            //rowsPerPage: 28,
            skuParameter: {
                nodes: [],
                text:""
            },
            page: 0,
            size:28
        },
        paginatedSkuList: {
            //paginatedSkuList: [],
            // totalCount:0
            //content:
            //data: [],
            //page: 0,
            //pageCount: 0,
            //pageSize: 0,
            //totalCount:0
            content: {
                data: [],
                page: 0,
                pageCount: 0,
                pageSize: 0,
                totalCount:0
            },
            error: null,
            success:false
        },

        isFilterSkuPending: false

    }
}

export const hierarchyAssignmentCreateSlice = createSlice({
    name: 'getTreeView',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },

        setinitalising: (state) => {
            state.loadedData.isinitalising = false;
        },

        getCheckboxStatus: (state, action: PayloadAction<number>) => {

            state.loadedData.productChildrenWithDataGridID.nodes = state.loadedData.productChildren;
            state.loadedData.productChildrenWithDataGridID.tableid = action.payload;
        },

        setDetermineParamterForPaginatedSku: (state, action: PayloadAction<DeterminePaginatedSkuParameter>) => {
            //state.dataSku.determine = action.payload;

            let param = action.payload;
            state.dataSku.determine.skuParameter = param.skuParameter
            state.dataSku.determine.page = param.page;
            //state.dataSku.determine.skuList = state.dataSku.skuList;
            state.dataSku.determine.skuParameter = state.dataSku.filterText

            console.log(state.dataSku.skuList, "check if skuList is filled with daga")
        },

        nodeToggle: (state, action: PayloadAction<string[]>) => {
            let nodesArray = action.payload;
            let counter = 0;
            let expandedNodes = state.data.expandedHierarchyAssigment.expandedNodes;

            expandedNodes.forEach((expand) => {

                nodesArray.forEach((node) => {

                    if (expand.parentNodeId === node) {

                        expandedNodes.slice(counter, counter + 1);
                    }
                })
                counter++;
            });

            state.data.expandedHierarchyAssigment.expandedNodes = expandedNodes;


        },

        getSearchCriteria: (state, action: PayloadAction<number>) => {

            let getSearchCriterions: ISearchCriterion = {
                searchValues: []
            };

            let sortedHierarchyAssigment: IProductPropertyNodeModel[];
            sortedHierarchyAssigment = state.data.propertySearchcriterion;
            state.data.SearchCriteria.tableId = action.payload;
            state.data.SearchCriteria.productNodesModel = sortedHierarchyAssigment;
            state.data.SearchCriteriaQuery.criterion.searchValues = getSearchCriterions.searchValues;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;          
        },
        getSearchCriteriaSku: (state, action: PayloadAction<ISkuCheckboxData>) => {

            let getSearchCriterions: ISearchCriterion = {
                searchValues: []
            };

            let sortedHierarchyAssigment: IProductPropertyNodeModel[];
            sortedHierarchyAssigment = state.data.propertySearchcriterion;
            state.data.SearchCriteriaSku.tableId = action.payload.key;
            state.data.SearchCriteriaSku.productNodesModel = sortedHierarchyAssigment;
            state.data.SearchCriteriaSku.sku = action.payload.sku;
            state.data.SearchCriteriaQuery.criterion.searchValues = getSearchCriterions.searchValues;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;
        },
        
        logicToggle:(state, action: PayloadAction<IUniqueKeyAndPageNumber>)=> {
            let expand: string[] = [];
            let searchCriterion = action.payload.unique; // is uniqueKey of node
            const searchValues = searchCriterion.split('/');    

            let result = setSearchCriterionArray(searchCriterion);
            state.data.sortedPropertyHierarchyAssigment = result;

            if (result) {
                state.dataSku.sortedPropertyHierarchyAssigmentSku = result;
            }

            state.data.propertySearchcriterion = determineSearchCriterion(state.data.propertyChildren, searchValues);
            state.data.propertySearchcriterion.forEach((x) =>
            {
                if(x.uniqueKey)
                expand.push(x.uniqueKey);
            });

            state.dataSku.showLastSelection = setExpandValue(expand, searchCriterion, state.dataSku.showLastSelection); //expand;            
            state.data.isNodeAlreadyClicked = false;
            state.data.showTable = true;


            let getProdutPropertyNodeModel = state.dataSku.sortedPropertyHierarchyAssigmentSku;
            state.dataSku.visibleProductListButton = true;

            if (getProdutPropertyNodeModel !== undefined) {

                if (getProdutPropertyNodeModel.length > 2) {
                    state.dataSku.visibleProductListButton = false;
                }
            }


            state.dataSku.hierarchyProperty.sortedPropertyHierarchyAssigment = result
            state.dataSku.hierarchyProperty.id = action.payload.paging;
         
            
            let getSearchCriterions: ISearchCriterion = {
                searchValues: []
            };

            let sortedHierarchyAssigment: IProductPropertyNodeModel[];

            state.data.sortedPropertyHierarchyAssigment.forEach(e => {

                getSearchCriterions.searchValues.push({ type: e.type, value: e.value });
            });
            

            sortedHierarchyAssigment = state.data.propertySearchcriterion;
            sortedHierarchyAssigment.reverse();
            state.data.SearchCriteriaQuery.productNodesModel = sortedHierarchyAssigment;
            state.data.SearchCriteriaQuery.productNodesModel.reverse();
            state.data.SearchCriteriaQuery.criterion.searchValues = getSearchCriterions.searchValues;

            //CreateCompositionOfSearchCriteia
            state.data.headlineCompositionOfSearchCriteria = createSearchCriteriaText(state.data.propertySearchcriterion, state.dataSku.sku);
        },  
        logicSelection: (state, action: PayloadAction<IUniqueKeyAndPreselectionNumber>)=> {
            //setSearchCriteriaArray
            let searchCriterion = action.payload.unique; // is uniqueKey of node
            const searchValues = searchCriterion.split('/');    

            let result = setSearchCriterionArray(searchCriterion);
            state.data.sortedPropertyHierarchyAssigment = result;

            if (result) {
                state.dataSku.sortedPropertyHierarchyAssigmentSku = result;
              
            }
            state.data.propertySearchcriterion = determineSearchCriterion(state.data.propertyChildren, searchValues);
                        
            state.data.isNodeAlreadyClicked = false;
            state.data.showTable = true;              

            //GetSelectedNodes
            let selectedHierarchyAssignment = state.data.sortedPropertyHierarchyAssigment;
            if (selectedHierarchyAssignment.length > 0) {
                selectedHierarchyAssignment.forEach((nodes => {


                    let productChildren = state.loadedData.productChildren;

                    if (productChildren.length !== 0) {
                        let propChild = productChildren.filter((value => { return value !== nodes ? nodes : null }));


                        if (propChild !== null) {
                            state.loadedData.productChildren.push({ type: nodes.type, value: nodes.value })
                        }
                    } else {
                        state.loadedData.productChildren.push({ type: nodes.type, value: nodes.value })
                    }
                }))
            }

            //GetSearchCriteriaWithoutTableId
            let getSearchCriterions: ISearchCriterion = {
                searchValues: []
            };

            let sortedHierarchyAssigment: IProductPropertyNodeModel[];

            state.data.sortedPropertyHierarchyAssigment.forEach(e => {

                getSearchCriterions.searchValues.push({ type: e.type, value: e.value });
            });

            sortedHierarchyAssigment = state.data.propertySearchcriterion;
            sortedHierarchyAssigment.reverse();
            state.data.SearchCriteriaQuery.productNodesModel = sortedHierarchyAssigment;
            state.data.SearchCriteriaQuery.productNodesModel.reverse();
            state.data.SearchCriteriaQuery.criterion.searchValues = getSearchCriterions.searchValues;

            //CreateCompositionOfSearchCriteia
            state.data.headlineCompositionOfSearchCriteria = createSearchCriteriaText(state.data.propertySearchcriterion, state.dataSku.sku);

            //SetPlain
            let getProdutPropertyNodeModel = state.dataSku.sortedPropertyHierarchyAssigmentSku;
            state.dataSku.visibleProductListButton = true;

            if (getProdutPropertyNodeModel !== undefined) {

                if (getProdutPropertyNodeModel.length > 2) {
                    state.dataSku.visibleProductListButton = false;
                }
            }

            //SetPreSelection
            state.data.preSelectionLength = action.payload.preselectionLength;
        },   setStandardTreeView: (state) => {
                //dispatch(setShowTableSku(false));
                state.dataSku.showSkuTable = false;
                // dispatch(setProductListFlag(false)); 
                state.dataSku.showProductlist = false;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;
                // dispatch(setPlain());
                let getProdutPropertyNodeModel = state.dataSku.sortedPropertyHierarchyAssigmentSku;
            state.dataSku.visibleProductListButton = true;

            if (getProdutPropertyNodeModel !== undefined) {

                if (getProdutPropertyNodeModel.length > 2) {
                    state.dataSku.visibleProductListButton = false;
                }
            }



            let currentSearchCriterion = state.data.SearchCriteriaQuery;

            let cleanedModels = removeSkuData(currentSearchCriterion.criterion.searchValues, currentSearchCriterion.productNodesModel);
            currentSearchCriterion.criterion.searchValues = cleanedModels.currentSearchValues;
            currentSearchCriterion.productNodesModel = cleanedModels.currentProductPropertyModels;

            state.data.SearchCriteriaQuery = currentSearchCriterion;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;
            state.dataSku.sku = "";

            state.data.headlineCompositionOfSearchCriteria = createSearchCriteriaText(state.data.propertySearchcriterion, state.dataSku.sku);

        },     

       

        setShowTableToFalse: (state, action: PayloadAction<boolean>) => {
            state.data.showTable = action.payload;
            state.data.HierarchyAssignmentTitle = "";
        },
        setShowTableSku: (state, action: PayloadAction<boolean>) => {

            state.dataSku.showSkuTable = action.payload;
        },
        filterSkus: (state, action: PayloadAction<string>) => {
            state.dataSku.isFilterSkuPending = true;
            state.dataSku.filterText.text = action.payload.toUpperCase();
            state.dataSku.filterText.nodes = state.data.sortedPropertyHierarchyAssigment;
        },
        setSingleSkuKey:(state, action: PayloadAction<string>) => {

            state.dataSku.sku= action.payload;
        },
        setshowlastSelectionEmpty: (state) => {
            state.dataSku.showLastSelection = [];
            state.data.showTable = false;
            state.data.isNodeAlreadyClicked = true;
        },
        
        setProductListFlag: (state, action: PayloadAction<boolean>) => {
            state.dataSku.showProductlist = action.payload;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;

        }, setHierarchyProperty: (state, action: PayloadAction<number>) => {
            state.dataSku.hierarchyProperty.sortedPropertyHierarchyAssigment = state.data.sortedPropertyHierarchyAssigment;
            state.dataSku.hierarchyProperty.id = action.payload;
        },
        setPlain: (state, action: PayloadAction<IProductPropertyNodeModel[]>) =>
        {
            let getProdutPropertyNodeModel = state.dataSku.sortedPropertyHierarchyAssigmentSku;
            state.dataSku.visibleProductListButton = true;

            if (getProdutPropertyNodeModel !== undefined) {

                if (getProdutPropertyNodeModel.length > 2) {
                    state.dataSku.visibleProductListButton = false;
                }
            }
        }, setIsProductListFlagForDeleteSku: (state, action: PayloadAction<boolean>) => {
            let status = action.payload;
            if (status) {
                state.data.SearchCriteriaSku.isProductList = false;
            } else {
                state.data.SearchCriteriaSku.isProductList = true;
            }
           
        },
        addSelectedSku: (state, action: PayloadAction<string>) =>
        {
            let currentSearchCriterion = state.data.SearchCriteriaQuery;
            state.dataSku.sku = action.payload;

            let cleanedModels = removeSkuData(currentSearchCriterion.criterion.searchValues, currentSearchCriterion.productNodesModel);
            currentSearchCriterion.criterion.searchValues = cleanedModels.currentSearchValues;
            currentSearchCriterion.productNodesModel = cleanedModels.currentProductPropertyModels;

            currentSearchCriterion.criterion.searchValues.push({ type: ProductTypeEnum.Sku, value: state.dataSku.sku })

            const parentProductModel = currentSearchCriterion.productNodesModel[currentSearchCriterion.productNodesModel.length -2];

            currentSearchCriterion.productNodesModel.push(
                {
                    parentNodeId: parentProductModel.nodeId,
                    text: state.dataSku.sku,
                    hasChildren: false,
                    children: null,
                    nodeId: state.dataSku.sku,
                    type: ProductTypeEnum.Sku,
                    uniqueKey: parentProductModel.uniqueKey + '/' + state.dataSku.sku ,
                    copyOldProperty: false,
                    value: state.dataSku.sku
                })


            state.dataSku.SearchCriteriaQuery = currentSearchCriterion;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;
            state.data.headlineCompositionOfSearchCriteria = createSearchCriteriaText(state.data.propertySearchcriterion, state.dataSku.sku);

        }, removeSelectedSku: (state) => {
            let currentSearchCriterion = state.data.SearchCriteriaQuery;

            let cleanedModels = removeSkuData(currentSearchCriterion.criterion.searchValues, currentSearchCriterion.productNodesModel);
            currentSearchCriterion.criterion.searchValues = cleanedModels.currentSearchValues;
            currentSearchCriterion.productNodesModel = cleanedModels.currentProductPropertyModels;      

            state.data.SearchCriteriaQuery = currentSearchCriterion;
            state.data.SearchCriteriaQuery.sideTable = state.dataSku.showSkuTable;
            state.dataSku.sku = "";

            state.data.headlineCompositionOfSearchCriteria = createSearchCriteriaText(state.data.propertySearchcriterion, state.dataSku.sku);
        },
        setProdutListtoFalse:(state) => {

            state.dataSku.showSkuTable = false;
            state.dataSku.showProductlist = false;
        }
    },


    extraReducers: (builder) => {


        builder.addCase(getPropertyNodesComponent.pending, (state) => {
            state.command.childrenRequest.status = "pending";
            state.data.isHierarchyPending = true;
            state.command.childrenRequest.canExecute = false;
            state.data.dataCheckDisplayCircularProgress = false;
            state.data.showTable = false;
        }).addCase(getPropertyNodesComponent.rejected, (state, action) => {
            state.command.childrenRequest.status = "error";
            state.command.childrenRequest.canExecute = true;
            state.data.dataCheckDisplayCircularProgress = false;
           state.data.showTable = false;

        }).addCase(getPropertyNodesComponent.fulfilled, (state, action) => {

            state.command.childrenRequest.status = "success";
            let propertyNodes: IProductPropertyNodeModel[] = state.data.propertyChildren || [];
            let copyOldNodes: boolean = false;
            let newNodesData = action.payload.getData();

            if (newNodesData.find((x) => x.copyOldProperty === true) && newNodesData.length === 1) {
                copyOldNodes = true;
            }

            if (newNodesData.length > 0) {
                let foundParentNode: IProductPropertyNodeModel = null;
                const parentNodeIds = determineParentNodeIds(newNodesData[0].uniqueKey)

                if (parentNodeIds) {

                    parentNodeIds.forEach((nodeId) => {

                        if (foundParentNode) {

                            foundParentNode = findNodeByIdMethode(foundParentNode, nodeId)

                        } else {
                            for (let rootNode of propertyNodes) {
                                foundParentNode = findNodeByIdMethode(rootNode, nodeId)

                                if (foundParentNode) {
                                    break;
                                }
                            }
                        }
                    });

                    if (foundParentNode) {
                        let nodedata = newNodesData.filter((value) => { return value.copyOldProperty === false ? value : null })

                        if (nodedata.length === 0) {
                            foundParentNode.children = null;
                        } else {
                            foundParentNode.children = nodedata;
                        }

                    }
                } else {
                    propertyNodes = newNodesData;
                }
            }

            if (newNodesData.length === 0 && state.data.SearchCriteriaQuery.criterion.searchValues.length) {
               
                //no new Children found end of Selection
                state.data.dataCheckDisplayCircularProgress = true;
                
            }

            newNodesData.forEach((child) => {

                if (!child.copyOldProperty) {


                    if (child.hasChildren && !child.children) {

                        child.children = [{ hasChildren: false, nodeId: child.nodeId + 'fake', parentNodeId: child.nodeId, text: '', type: 2, value: '', children: [], uniqueKey: child.uniqueKey + 'fake', copyOldProperty: false }];
                    }
                }
            })

            state.data.propertyChildren = propertyNodes;

            if (!copyOldNodes) {
                state.data.propertyChildrenOld = propertyNodes;
                //console.log(state.data.propertyChildrenOld, "copyOldNodes are true")
            } else {

                if (state.data.propertyChildrenOld != null) {

                    let propchildrenOld = state.data.propertyChildrenOld;
                    let nm = state.data.SearchCriteriaQuery.productNodesModel;

                    for (let x of propchildrenOld) {
                        let deletetetNode: IProductPropertyNodeModel = findNodeParentByUnqiueKey(x, nm[nm.length - 1].uniqueKey)

                        if (deletetetNode) {

                            deletetetNode.children = null;
                            deletetetNode.hasChildren = false;
                            break;
                        }
                    }
                }
            }

            state.data.areChildrenReady = true;
            state.data.copyoldPropertyflag = copyOldNodes;
            state.data.isHierarchyPending = false;


            if (newNodesData.length > 0) {
                let splituniqueKey = newNodesData[0].uniqueKey.split('/');

                if (splituniqueKey.length > 1) {

                    state.data.dataCheckDisplayCircularProgress = true

                    if (!state.data.isNodeAlreadyClicked) {
                        state.data.showTable = true;
                    }
                    
                    //console.log("active table")
                } else {

                    state.data.dataCheckDisplayCircularProgress = false
                }

                state.data.dataCheckDisplayCircularProgress = true
            }
        }).addCase(ResultpaginatedSkuList.pending, (state) => {

        }).addCase(ResultpaginatedSkuList.rejected, (state) => {

        }).addCase(ResultpaginatedSkuList.fulfilled, (state, action) => {
            state.dataSku.paginatedSkuList = action.payload.getData();
            state.dataSku.isFilterSkuPending = false;
        });
    }
})

export const {
    resetState,
    setinitalising,
    nodeToggle,
    getCheckboxStatus,
    getSearchCriteria,
    setShowTableToFalse,
    setProductListFlag,
    filterSkus,
    setPlain,
    getSearchCriteriaSku,
    addSelectedSku,
    setShowTableSku,
    removeSelectedSku,
    setshowlastSelectionEmpty,
    setProdutListtoFalse,
    setIsProductListFlagForDeleteSku,
    setHierarchyProperty,
    logicToggle,
    logicSelection,
    setStandardTreeView,
    setDetermineParamterForPaginatedSku

}= hierarchyAssignmentCreateSlice.actions

export default hierarchyAssignmentCreateSlice.reducer



