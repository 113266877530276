import { ProductManagerViewUpdate } from "./productmasterUpdate.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateProductMaster} from "./productmasterUpdate.thunks";
import { IProductmaster } from "models/productmastertable/productmastertable.model";
import { checkIfInputFieldNameWasFilledmaster } from "../../component/productmaster.reducer"
import { initComponentPm } from "../../redux/productmaster.thunks";


const initialState: ProductManagerViewUpdate = {
    loadedData: {
        productmaster: {
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
        }
       
    },
    command: {
        updateProductmaster: { status: "idle", canExecute: false },
        UpdateDbProductmaster: { status: "idle", canExecute: false }


    },
    data: {
        productmaster: { 
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
        },
        errortextbox: false,
        requirefield: false
    }
}

export const productmasterUpdateSlices = createSlice({
    name: 'getTreeView',
    initialState,
    reducers: {
        resetStateUpdate: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        setDescriptionUpdate:(state, action: PayloadAction<string>) =>{
            state.data.productmaster.name = action.payload;
            state.data.requirefield = checkIfInputFieldNameWasFilledmaster(state.data.productmaster);
        },
        setLongDescriptionUpdate: (state, action: PayloadAction<string>) => {
            state.data.productmaster.description = action.payload;  
            state.data.requirefield = checkIfInputFieldNameWasFilledmaster(state.data.productmaster);
        },
        setErrorTextField: (state, action: PayloadAction<boolean>) => {
            state.data.errortextbox = action.payload;
            state.command.UpdateDbProductmaster.status = "idle"
        },
        updateProduct: (state, action: PayloadAction<IProductmaster>) => {
            state.loadedData.productmaster = action.payload
            state.data.productmaster = action.payload;
        },
        setDynamicCheckbox: (state, action: PayloadAction<boolean>) => {
            
            state.data.productmaster.dynamic = action.payload;
            state.data.requirefield = checkIfInputFieldNameWasFilledmaster(state.data.productmaster);
        },
        setRequireFieldToFalseUpdate: (state) => {
            state.data.requirefield = false;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(initComponentPm.pending, (state) => {
            state.command.updateProductmaster.status = "pending"
            state.command.updateProductmaster.canExecute = false;
        }).addCase(initComponentPm.rejected, (state, action) => {
            state.command.updateProductmaster.status = "error"
            state.command.updateProductmaster.message = action.error.message;
            state.command.updateProductmaster.canExecute = true;
        }).addCase(initComponentPm.fulfilled, (state, action) => {
            state.command.updateProductmaster.status = "success"
            state.command.updateProductmaster.canExecute = true;
            state.data.productmaster.id = state.loadedData.productmaster.id;
            
        
        }).addCase(updateProductMaster.pending, (state) => {
            state.command.UpdateDbProductmaster.status = "pending"
            state.command.UpdateDbProductmaster.canExecute = false;
        }).addCase(updateProductMaster.rejected, (state, action) => {
            state.command.UpdateDbProductmaster.status = "error"
            state.command.UpdateDbProductmaster.message = action.error.message;
            state.command.UpdateDbProductmaster.canExecute = true;
        }).addCase(updateProductMaster.fulfilled, (state, action) => {
            state.command.UpdateDbProductmaster.status = "success"
            state.command.UpdateDbProductmaster.canExecute = true;
        })
    }


})

export const {
    resetStateUpdate,
    setDescriptionUpdate,
    setLongDescriptionUpdate,
    updateProduct,
    setErrorTextField,
    setDynamicCheckbox,
    setRequireFieldToFalseUpdate
}= productmasterUpdateSlices.actions

export default productmasterUpdateSlices.reducer