
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = {
    de: "de",
    en: "en",
};

i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .use(LanguageDetector)
    .init({
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    defaultVersion: "1.0",
                    expirationTime: 0
                },
                {
                    loadPath: "/api/Localization/{{ns}}?language={{lng}}"
                }
            ]
        },
        react: {
            useSuspense: true
        },
        detection: {
            caches: [],
            excludeCacheFor: []
        },
        ns: ["ESHOPPM"],
        defaultNS: "ESHOPPM",
        supportedLngs: [languages.de, languages.en],
        fallbackLng: languages.en,
        nonExplicitSupportedLngs: true
    });

export default i18next;
