import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import AccountAreaComponent from "../../../authentication/account_area/account_area.component";
import theme from "../../style/theme";
import mediLogo from "./medi.png"
import { useTranslation } from 'react-i18next';

const Headline = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
`;

const Logo = styled.img`
  margin-right: 15px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const AccountAreaWrapper = styled.div`
  margin-left: auto;
  align-content: flex-end;
`;

const HeaderArea = styled.div`
  color: ${theme.colors.text};
  flex-shrink: 0;
`;

export const HeaderComponent = () => {

    const { t } = useTranslation();

  return (
    <HeaderArea>
      <div>
        <Container>
          <Box>
            <Logo src={mediLogo} alt="medi logo" />
            <Headline>{t("appName")}</Headline>
            <AccountAreaWrapper>
              <AccountAreaComponent></AccountAreaComponent>
            </AccountAreaWrapper>
          </Box>
        </Container>
      </div>
    </HeaderArea>
  );
};
