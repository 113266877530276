import UnauthorizedComponent from "../../authentication/unauthorized/unauthorized.component";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { publicClientApplication } from "../../services/aad.service";

interface GuardProps {
  requiredRole?: string;
  children?: React.ReactElement;
}

const DefaultGuard: React.FC<GuardProps> = ({ requiredRole, children }) => {
  useMsalAuthentication(InteractionType.Redirect);

  const accounts = publicClientApplication.getAllAccounts();
  const isAuthenticated = useIsAuthenticated();
  const accountRoles = accounts[0]?.idTokenClaims?.roles;

  const isNoRoleRequried = () => requiredRole === "" || requiredRole == null;
  const isNoRoleAvailable = () =>
    accountRoles == null || accountRoles.length === 0;
  const isNoMatchingRole = () => !accountRoles.includes(requiredRole);

  if (!isAuthenticated) {
    return <UnauthorizedComponent />;
  }

  if (isNoRoleRequried() && isAuthenticated) {
    return children;
  }

  if (isNoRoleAvailable()) {
    return <UnauthorizedComponent />;
  }

  if (isNoMatchingRole()) {
    return <UnauthorizedComponent />;
  }

  return children;
};

export default DefaultGuard;
