export interface IProductmaster {
    id: number,
    abbreviation: string,
    name: string,
    description: string,
    dynamic: boolean,
    isCheckbox: boolean,
    indeterminateStatus: number,
    assignment: AssigmentStatus,
    searchCriteria: string,
    defaultCheckboxValue?: boolean,
    indeterminateValue?: boolean,
    inherit: boolean,
    sortedId: number;
    skus: Record<string, boolean>;
}

export enum AssigmentStatus {
    None=0,
    Indeterminate=1,
    All = 2,
    Inherit=3
}


export interface IProductmasterAdd {
    id: number,
    abbreviation: string,
    name: string,
    description: string,
    dynamic: boolean,
    isCheckbox: boolean,
    takeOverFrom :boolean
}











