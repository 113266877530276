import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import ProductmasterService from "services/productmaster.service";

export const updateProductMaster = createAsyncThunk(
    'articletunks/articletunks/addCountry',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.updateProductMasterTable(state.dataGridUpdate.data.productmaster);
        handleResponseErrors(response, "articletunks");
        return response;
    }
)


