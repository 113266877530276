import { ProductTunksTableViewModel } from "./productmaster.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initComponentPm, updateProductMasterTable } from "./productmaster.thunks";
import { IProductmaster } from "models/productmastertable/productmastertable.model"
import { determineCheckboxStatusInProductmanager, filterpoint } from "../component/productmaster.reducer";
const initialState: ProductTunksTableViewModel = {
    loadedData: {
        articletabledata: [],
        searchFilter: "",
        changeCheckboxStatus: {
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
            },
            selectedtabledata: [],
            availabletabledata: []
    },
    command: {
        getProductMasterTable: { status: "idle", canExecute: false },
        getProductMasterDelete: { status: "idle", canExecute: false },
        getProductMasterUpdate: { status: "idle", canExecute: false },
        getProductMasterTitle: { status: "idle", canExecute: false }
    },
    data: {
        articledata: {
            dynamic:false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
        }    
    },
    savetabledata: {
        savedTableItems: []
    },
    deleteAProductmaster: false,
    isTablePending: false
}

export const productmasterCreateSlice = createSlice({
    name: 'getTreeView',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },

        deleteProduct: (state, action: PayloadAction<IProductmaster>) => {
            state.data.articledata = action.payload;
        },

        

        filterpoints(state, action: PayloadAction<string>) {
             state.loadedData.searchFilter = action.payload;
            state.loadedData.articletabledata = filterpoint(state.savetabledata.savedTableItems, state.loadedData.searchFilter);
        },

        setDeleteProductmasterToTrue(state) {
            state.deleteAProductmaster = true;
        },

        setDeleteProductmasterToFalse(state, action: PayloadAction<string>) {
            state.deleteAProductmaster = false;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(initComponentPm.pending, (state) => {
            state.command.getProductMasterTable.status = "pending"
            state.command.getProductMasterTable.canExecute = false;
            state.isTablePending = true;
        }).addCase(initComponentPm.rejected, (state, action) => {
            state.command.getProductMasterTable.status = "error"
            state.command.getProductMasterTable.message = action.error.message;
            state.command.getProductMasterTable.canExecute = true;
        }).addCase(initComponentPm.fulfilled, (state, action) => {
            state.command.getProductMasterTable.status = "success"
            state.command.getProductMasterTable.canExecute = true;
            state.loadedData.articletabledata = determineCheckboxStatusInProductmanager(action.payload.getData());
            state.savetabledata.savedTableItems = action.payload.getData();
            state.isTablePending = false;

            let selectedtabledata: IProductmaster[]= []; 
            let availabletabledata: IProductmaster[]= []; 
            let acounter =0;
            let scounter =0;
            state.loadedData.articletabledata.forEach((x)=> 
            {
                if(x.assignment===0){
                    selectedtabledata.push(x);
                }else{
                    availabletabledata.push(x);
                }
            })

            selectedtabledata.forEach((u)=> { u.sortedId = scounter++})
            availabletabledata.forEach((u)=> { u.sortedId = acounter++})

            state.loadedData.selectedtabledata = selectedtabledata;
            state.loadedData.availabletabledata = availabletabledata;
        });

        builder.addCase(updateProductMasterTable.pending, (state) => {
            state.command.getProductMasterUpdate.status = "pending"
            state.command.getProductMasterUpdate.canExecute = false;
        }).addCase(updateProductMasterTable.rejected, (state, action) => {
            state.command.getProductMasterUpdate.status = "error"
            state.command.getProductMasterUpdate.message = action.error.message;
            state.command.getProductMasterUpdate.canExecute = true;
        }).addCase(updateProductMasterTable.fulfilled, (state, action) => {
            state.command.getProductMasterUpdate.status = "success"
            state.command.getProductMasterUpdate.canExecute = true;
            state.data.articledata = action.payload.getData();
        })
    }
})

export const {
    resetState,
    deleteProduct,
    filterpoints,
    setDeleteProductmasterToFalse,
    setDeleteProductmasterToTrue
   
    
}= productmasterCreateSlice.actions

export default productmasterCreateSlice.reducer