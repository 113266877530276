import { LoadingIndicatorComponent } from "../../loading_indicator/loading_indicator.component";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import * as style from './dropdown.style';
import { isNotUndefinied } from 'services/validation.service';

interface DropdownProps {
  name: string;
  data: Array<any>;
  displayedProperties?: Array<string>;
  isRequired?: boolean;
  isLoading?: boolean;
  isReadonly?: boolean;
  onSelect: (selectedItem: any) => void;
  selectedValue?: any;
  renderEntry?: (entry: any) => string;
    errorText?: string;
    isHidden?: boolean;
}



export const DropdownComponent: React.FC<DropdownProps> = ({
  data,
  displayedProperties,
  name,
  onSelect,
  isLoading,
  isReadonly,
  isRequired,
  selectedValue,
  renderEntry,
  errorText,
    isHidden
}) => {

  const [inputValue, setInputValue] = useState('');
  const isErrorVisible = errorText !== "" && errorText != null;

  const styles = style.useStyles();

  const inputStyle = isErrorVisible ? styles.error : styles.normal;

  if(!isNotUndefinied(selectedValue))
  {
    selectedValue = null;
  }

  if (isLoading) {
    return (
      <TextField
        name={name}
        label={name}
        required={isRequired}
            disabled={true}
            hidden={isHidden }
        InputProps={{
          endAdornment: (
            <LoadingIndicatorComponent size="1rem" />
          )
        }}
      />)
  }

  return (
    <FormControl
      
          required={isRequired}
          hiddenLabel={isHidden}
          hidden={isHidden }
    >
      <Autocomplete
        noOptionsText={'Keine Auswahl verfügbar'}
        id={name}
        classes={{ input: inputStyle }}
        disabled={isReadonly}
        disableClearable
        value={selectedValue}
        onChange={(event: any, selectedItem: any) => {
          onSelect(selectedItem)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={data}
        getOptionLabel={(option) => {
          if (renderEntry !== undefined) {
            return renderEntry(option)
          } else {
            return displayedProperties
              .map((property) => option[property])
              .filter(entry => entry !== null && entry !== undefined)
              .join(" - ")
          }
        }
        }
        renderInput={(params) => {
            return (<TextField {...params} label={name} margin="none" required={isRequired} hidden={isHidden } />);
        }}
      />
      { isErrorVisible ? <style.ErrorWrapper>{errorText}</style.ErrorWrapper>:<></>}
    </FormControl>
  );
};
