
import { ApiResult } from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import { getConfig } from './config.service';
import { ISortedHierarchyAssigment, ISortedHierarchy } from "models/productmastertreeview/Isortedproducttreeview.model"
import { DeterminePaginatedSkuParameter,  IHierarchyAssigment, IProductPropertyNodeModel, PaginatedSkuResult } from "models/selectproductmanager/setproducts/productPropertyNodeModel";

class ProductTreeViewService extends HttpService {
    public async initComponentTreeView(): Promise<ApiResult<ISortedHierarchyAssigment>>{
        return this.get<ISortedHierarchyAssigment>("/api/HierarchyAssignment");
    }
    public async sortTreeViewComponent(): Promise<ApiResult<ISortedHierarchy>> {
        return this.get<ISortedHierarchy>("/api/HierarchyAssignment"); 
    }

    public async getPropertyNodes(nodes: IHierarchyAssigment): Promise<ApiResult<Array<IProductPropertyNodeModel>>> {
        return this.postDataForData("/api/HierarchyAssignment/getpropertynodes", nodes);
    }

 

    public async paginatedSku(result: DeterminePaginatedSkuParameter): Promise<ApiResult<PaginatedSkuResult>>{
        return this.postDataForData(`/api/HierarchyAssignment/getskus/?page=${result.page}&size=${result.size}`, result);
        
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ProductTreeServiceAPI = new ProductTreeViewService(TimeoutMilliseconds);

export default ProductTreeServiceAPI;