
import { ApiResult } from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import { getConfig } from './config.service';
import { IProductmaster, IProductmasterAdd } from "models/productmastertable/productmastertable.model";
import { ISearchCriterionModel, ISearchModel, ISearchModelSku } from "../models/productmaster/SearchCriteriaModel";




class ProductTunksViewService extends HttpService {
    public async initComponent(nodes: ISearchCriterionModel): Promise<ApiResult<Array<IProductmaster>>>{
        return this.postDataForData("/api/ProductmasterTable/tablequery", nodes);
    }

    public async addProductMasterTable(dataQuery: IProductmasterAdd):Promise<ApiResult<Number>>{
        return this.postForId<IProductmasterAdd>("/api/ProductmasterTable", dataQuery);
    }

    public async updateProductMasterTable(dataQuery: IProductmaster):Promise<ApiResult<void>>{
        return this.put<IProductmaster>("/api/ProductmasterTable", dataQuery);
    }

    public async deleteProductMasterTable(id: number): Promise<ApiResult<IProductmaster>>{
        return this.deleteWithResult<IProductmaster>(`/api/ProductmasterTable/${id}`);
    }

    public async getProductMasterTable(id: number): Promise<ApiResult<IProductmaster>> {
        return this.get<IProductmaster>(`/api/ProductmasterTable/${id}`);
    } 
 
    public async postSearchCriteria(selection: ISearchModel): Promise<ApiResult<boolean>> {
        return this.postDataForData("/api/ProductmasterTable/searchcritieria", selection);
    }

    public async postSearchCriteriaToDeleteThem(selection: ISearchModel): Promise<ApiResult<boolean>> {
        return this.postDataForData("/api/ProductmasterTable/deletesearchcritieria", selection);
    }

    public async postSearchCriteriaSku(selection: ISearchModelSku): Promise<ApiResult<boolean>> { // Todo model
        return this.postDataForData("/api/ProductmasterTable/searchcritieriasku", selection); 
    }

    public async postSearchCriteriaToDeleteThemSku(selection: ISearchModelSku): Promise<ApiResult<boolean>> {  //Todo model
        return this.postDataForData("/api/ProductmasterTable/deletesearchcritieriaSku", selection);
    }

    public async GetProductmaster(selection: ISearchModel): Promise<ApiResult<Array<IProductmaster>>> {
        return this.postDataForData("/api/ProductmasterTable/productmasterdataquery", selection);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ArticletunksServiceAPI = new ProductTunksViewService(TimeoutMilliseconds);

export default ArticletunksServiceAPI;