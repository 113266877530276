export const getConfig = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case "prod":
            return {
                name: "prod",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "8d0583df-3ad8-411e-9ed7-f43435320b3d",
                authServerAppId: "8d7dd0df-09de-4f38-a07b-7c1b03379356",
                httpTimeoutMs: 30000,
                showSwaggerLinks: false,
            };
        case "stage":
            return {
                name: "stage",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "8302a121-f38e-410b-aa80-ddfc0ce67014",
                authServerAppId: "e0047dcb-5d85-4100-aef3-6e00c349397a",
                httpTimeoutMs: 30000,
                showSwaggerLinks: false,
            };
        case "dev":
        default:
            return {
                name: "dev",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "e2526236-e29d-484f-8158-fde98117f377",
                authServerAppId: "66a9410e-2eeb-48c6-819e-7fc9c4c6c7ac",
                httpTimeoutMs: 300000,
                showSwaggerLinks: true,
            };
    }
}