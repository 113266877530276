import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import DefaultGuard from "./shared/guards/default.guard";
import { createRoot } from 'react-dom/client';
import { publicClientApplication } from "./services/aad.service";
import { MsalProvider } from "@azure/msal-react";
import "./i18n/i18n";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <MsalProvider instance={publicClientApplication}>
    <DefaultGuard requiredRole="prodm.user">
      <App />
    </DefaultGuard>
  </MsalProvider>
);

// root.render(<React.StrictMode>
//   <MsalProvider instance={publicClientApplication}>
//     <DefaultGuard requiredRole="mtm.user">
//       <App />
//     </DefaultGuard>
//   </MsalProvider>
// </React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  namespace JSX {
    interface IntrinsicElements {
    }
  }
}