import { EnumDescriptor } from "../../enum_descriptor";

export enum ProductTypeEnum {
    Undefined,
    MainProductLine,
    ProductLine,
    ProductType,
    CompressionClass,
    ProductionType,
    Color,
    AdditionalDesign,
    Foot,
    Shortened,
    FullyKnitted,
    ExtraWideCalf,
    CompressivePantyTop,
    Side,
    Size,
    QuantityUnit,
    Gender,
    SinglePiece,
    SalesGroupEmployess,
    PersonalSales,
    BusinessArea,
    Sku
}

export const ProductValues: Array<EnumDescriptor<ProductTypeEnum>
> = [
        { value: ProductTypeEnum.Undefined, text: "Please select" },
        { value: ProductTypeEnum.MainProductLine, text: "MainProductLine" },
        { value: ProductTypeEnum.ProductLine, text: "ProductLine" },
        { value: ProductTypeEnum.ProductType, text: "ProductType" },
        { value: ProductTypeEnum.CompressionClass, text: "CompressionClass" },
        { value: ProductTypeEnum.ProductionType, text: "ProductionType" },
        { value: ProductTypeEnum.Color, text: "Color" },
        { value: ProductTypeEnum.AdditionalDesign, text: "AdditionalDesign" },
        { value: ProductTypeEnum.Foot, text: "Foot" },
        { value: ProductTypeEnum.Shortened, text: "Shortened" },
        { value: ProductTypeEnum.FullyKnitted, text: "FullyKnitted" },
        { value: ProductTypeEnum.ExtraWideCalf, text: "ExtraWideCalf" },
        { value: ProductTypeEnum.CompressivePantyTop, text: "CompressivePantyTop" },
        { value: ProductTypeEnum.Side, text: "Side" },
        { value: ProductTypeEnum.Size, text: "Size" },
        { value: ProductTypeEnum.QuantityUnit, text: "QuantityUnit" },
        { value: ProductTypeEnum.Gender, text: "Gender" },
        { value: ProductTypeEnum.SinglePiece, text: "SinglePiece" },
        { value: ProductTypeEnum.SalesGroupEmployess, text: "SalesGroupEmployess" },
        { value: ProductTypeEnum.PersonalSales, text: "PersonalSales" },
        { value: ProductTypeEnum.BusinessArea, text: "BusinessArea" },
        { value: ProductTypeEnum.Sku, text: "Sku" },
    ]
