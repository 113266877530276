const colors = {
  reallyLightGray: "#f6f8f9",
  mediSecondaryGray: "#e9edf0",
  mediLightGray: "#D7DEE2",
  mediGray: "#48586a",
  mediMagenta: "#e6007e",
  error: "#ff0000",
  mediRed: "#fe1f1f",
  mediGreen: "#8ffe16",
  mediBlue: "#91b1be",
  warning: "#ed8b00",
  success: "#8ea40d",
  darkGreenMedi:"#726d00"
};

export default colors;
