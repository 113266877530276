
import { IProductmaster, IProductmasterAdd } from "models/productmastertable/productmastertable.model";


export const checkIfAllInputFieldsAreFilledInAdd = ( productmaster:IProductmasterAdd) => {

    var retval = false;
    if (productmaster.abbreviation.length>0 && productmaster.name.length>0) {

        retval = true;
    }

    return retval;
}


export const checkIfInputFieldNameWasFilled = (productmaster: IProductmasterAdd) => {

    var retval = false;
    if (productmaster.name.length>0) {

        retval = true;
    }

    return retval;
}

export const checkIfInputFieldDescriptionWasFilled = (productmaster: IProductmasterAdd) => {

    var retval = false;
    if (productmaster.description.length > 0) {

        retval = true;
    }

    return retval;
}

export const checkIfInputFieldNameWasFilledmaster = (productmaster: IProductmaster) => {

    var retval = false;
    if (productmaster.name.length > 0) {

        retval = true;
    }

    return retval;
}

export const checkIfInputFieldDescriptionWasFilledmaster = (productmaster: IProductmaster) => {

    var retval = false;
    if (productmaster.description.length > 0) {

        retval = true;
    }

    return retval;
}
export const checkIfAllInputFieldsAreFilledInAddmaster = (productmaster: IProductmaster) => {

    var retval = false;
    if (productmaster.abbreviation.length > 0 && productmaster.name.length > 0) {

        retval = true;
    }

    return retval;
}

export const generateAlertDialogDescription = (dialoge01: string, dialoge02: string, productmaster: IProductmaster) => {

    var dialogdescription = dialoge01 + " [ " + productmaster.abbreviation + " ] " + productmaster.name + " " + dialoge02;
    return dialogdescription;
}

export const determineCheckboxStatusInProductmanager = (productmaster: IProductmaster[]) => {
    let getnewproductmaster: IProductmaster[] = [];

    productmaster.forEach((entry) => {

        if (entry.inherit === true && entry.assignment === 2) {
            getnewproductmaster.push({ description: entry.description, id: entry.id, dynamic: entry.dynamic, abbreviation: entry.abbreviation, assignment:3, name: entry.name, indeterminateStatus: entry.indeterminateStatus, defaultCheckboxValue: true, isCheckbox: entry.isCheckbox, searchCriteria: entry.searchCriteria, indeterminateValue: false, inherit: entry.inherit , sortedId:0, skus:entry.skus});
           
        } else { 
            switch (entry.assignment) {
                case 0: getnewproductmaster.push({ description: entry.description, id: entry.id, dynamic: entry.dynamic, abbreviation: entry.abbreviation, assignment: entry.assignment, name: entry.name, indeterminateStatus: entry.indeterminateStatus, defaultCheckboxValue: false, isCheckbox: entry.isCheckbox, searchCriteria: entry.searchCriteria, indeterminateValue: false, inherit: false, sortedId:0, skus:entry.skus }); break;
                case 1: getnewproductmaster.push({ description: entry.description, id: entry.id, dynamic: entry.dynamic, abbreviation: entry.abbreviation, assignment: entry.assignment, name: entry.name, indeterminateStatus: entry.indeterminateStatus, defaultCheckboxValue: false, isCheckbox: entry.isCheckbox, searchCriteria: entry.searchCriteria, indeterminateValue: true, inherit: false, sortedId:0, skus:entry.skus }); break;
                case 2: getnewproductmaster.push({ description: entry.description, id: entry.id, dynamic: entry.dynamic, abbreviation: entry.abbreviation, assignment: entry.assignment, name: entry.name, indeterminateStatus: entry.indeterminateStatus, defaultCheckboxValue: true, isCheckbox: entry.isCheckbox, searchCriteria: entry.searchCriteria, indeterminateValue: false, inherit: entry.inherit,sortedId:0, skus:entry.skus }); break;
            }
        }
    });

    return getnewproductmaster;
}



export const filterpoint = (points: Array<IProductmaster>, searchText: string) => {
    //todo filter is not working
    const lowerCaseSearchText = searchText.toLocaleLowerCase().trim();
    return points.filter(
        (points) => {

            let lowerCaseAbbreviation = points.abbreviation.toLocaleLowerCase();
            let lowerCaseName = points.name.toLocaleLowerCase();

            //check abbreviation 
            if (lowerCaseAbbreviation.includes(lowerCaseSearchText) && points.abbreviation !== null)
                return points;



            //check description
            if (lowerCaseName.includes(lowerCaseSearchText) && points.name !== null)
                return points;



            //check if user search for dynmatic
            if (lowerCaseSearchText === 'dyn') {

                if (points.dynamic === true) {

                    return points;

                }
            }
            return '';
        }
    );
}

