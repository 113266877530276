import { ProductTypeEnum, ProductValues } from "models/selectproductmanager/setproducts/productSelection_Type_Enum";
import { IPreSelectedProductTypEnum } from "../../../models/productmaster/products/IPreSelectedProductTypEnum";
import { IProductCategory } from "../../../models/productmastercategory/productmastercategory.model";



export const updatePreselectionProductItems = (newSelection: IPreSelectedProductTypEnum, previousSelection: IPreSelectedProductTypEnum[]) => {

    let oldSelection = previousSelection;
    let index = newSelection.index - 1;
    
    

    if (oldSelection !== null && newSelection !== null) {

        //check if previous have selected Please Select
        if (previousSelection[index].selectedEnum.value === ProductTypeEnum.Undefined) {
            //set new selection
            const item = { index: newSelection.index + 1, selectedEnum: ProductValues[0], possibleStates: getPossibleState(previousSelection, newSelection) };
            return item;
        }
    }
}


export const getPossibleState = (previousSelection: IPreSelectedProductTypEnum[], newSelection: IPreSelectedProductTypEnum) => {

    let index = newSelection.index - 1;
    
    let getPossibleStateList = previousSelection[index].possibleStates;
    let filterprop: IProductCategory[] = [];
    if (getPossibleStateList.length > 0) {

        //search and remove selected value from user and also remove Undefined
        filterprop = getPossibleStateList.filter((search) => {
            return search.value !== newSelection.selectedEnum.value &&  search.value !== ProductTypeEnum.Undefined
        });
    }

    return filterprop;
}

export const UpdatePreSelectionUnEqualUndefined = (newSelection: IPreSelectedProductTypEnum, previousSelection: IPreSelectedProductTypEnum[], oldSelect: IProductCategory) => {

    
    previousSelection.forEach((element, index) => {

        if (element.index === newSelection.index) {
             
            previousSelection[index].selectedEnum = newSelection.selectedEnum;
        }
        element.possibleStates.push(oldSelect);
    });
    return previousSelection;
}

export const setpossibleSelection = (possibleselection: Array<IProductCategory>, preselection: Array<IProductCategory>, prepossibleselection: Array<IProductCategory>) => {
    possibleselection.map((e) => prepossibleselection.push({ value: e.value, text: e.text }));
    let getEntryPreSelection = prepossibleselection.map((e) => e);
    const sources = getEntryPreSelection.filter(node => preselection.every((tail) => tail.value !== node.value))
    return sources;
}


export const GetSelectedItem = (selection: IPreSelectedProductTypEnum[]) => {

    const selctedItems = selection.map((item => item.selectedEnum));
    selctedItems.pop();
    return selctedItems;
}


export const SortASC = ( preSelection : IPreSelectedProductTypEnum[]) =>
{
     // sort alphatisch
    preSelection.forEach(x => {
        let sortpossiblestate = x.possibleStates.sort((a, b) => a.text.localeCompare(b.text));
        x.possibleStates = sortpossiblestate;
    });

    return preSelection;
}