import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Right = styled.div`
  align-content: flex-end;
  margin-left: auto;
`;

export const Left = styled.div`
  align-self: center;
  justify-self: flex-start;
`;

export const Center = styled.div`
  align-content: center;
  margin-left: auto;
`;
