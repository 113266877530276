import { ApiResult } from "./api_result";
import { fetchWithTimeout } from "./fetch_with_timeout";
import { authenticationParameters, publicClientApplication } from "./../../services/aad.service";
import { getConfig } from "services/config.service";

class HttpService {
  timeoutMs: number;

  constructor(timeoutMs?: number) {
    if(timeoutMs) {
      this.timeoutMs = timeoutMs;
    } else {
      const appConfig = getConfig();
      this.timeoutMs = Number(appConfig.httpTimeoutMs);
    }
  }

  private async getAccessTokenViaMSAL():Promise<string> {
    const accounts = publicClientApplication.getAllAccounts();
    if (accounts.length > 0) {
        const request = {
            scopes: authenticationParameters.scopes,
            account: accounts[0]
        }
        const accessToken = await publicClientApplication.acquireTokenSilent(request).then((response) => {
            return response.accessToken;
        }).catch(error => {
            // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
            console.log(error);
            return null;
        });

        return accessToken;
    }

    return null;
}

  public async getAccessTokenHeader(): Promise<string> {
    const accessToken = await this.getAccessTokenViaMSAL();
    return `Bearer ${accessToken}`;
  }

  protected async get<T>(path: string): Promise<ApiResult<T>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<T>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<T>().withTimeout();
    }
  }

  protected async post<T>(path: string, content: T): Promise<ApiResult<void>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      return new ApiResult<void>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }

  protected async postForId<T>(path: string, content: T): Promise<ApiResult<Number>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<Number>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.text();
        result.withData(Number(data));
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<Number>().withTimeout();
    }
  }

  protected async postForData<T>(path: string): Promise<ApiResult<T>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<T>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<T>().withTimeout();
    }
  }

  protected async postDataForData<TIn, TOut>(path: string, content: TIn): Promise<ApiResult<TOut>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        body: JSON.stringify(content),
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<TOut>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<TOut>().withTimeout();
    }
  }

  protected async postForm(path: string, content: FormData): Promise<ApiResult<void>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: content,
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      return new ApiResult<void>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }

  protected async uploadFileForm<T>(path: string, content: FormData): Promise<ApiResult<T>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: content,
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      if (response.status === 422) {
        return new ApiResult<T>()
          .withStatusCode(response.status)
          .withData(await response.json());
      }
      return new ApiResult<T>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<T>().withTimeout();
    }
  }

  protected async editFileForm<T>(path: string, content: FormData): Promise<ApiResult<T>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "PUT",
        body: content,
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      if (response.status === 422) {
        return new ApiResult<T>()
          .withStatusCode(response.status)
          .withData(await response.json());
      }
      return new ApiResult<T>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<T>().withTimeout();
    }
  }

  protected async put<T>(path: string, content?: T): Promise<ApiResult<void>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "PUT",
        body: content ? JSON.stringify(content) : null,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      return new ApiResult<void>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }

  protected async delete(path: string): Promise<ApiResult<void>> {
    try {
      const accessToken = await this.getAccessTokenHeader();

      const response = await fetchWithTimeout(path, {
        method: "DELETE",
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      return new ApiResult<void>().withStatusCode(response.status);
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }

  protected async deleteWithResult<T>(path: string): Promise<ApiResult<T>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "DELETE",
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      return new ApiResult<T>()
        .withStatusCode(response.status)
        .withData(await response.json());
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<T>().withTimeout();
    }
  }
}

export default HttpService;
