import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//import InfoIcon from '@mui/icons-material/Info';
import styled from "styled-components";
import InfoIcon from '@material-ui/icons/Info';
import { Box } from "@material-ui/core/";
export const StyledHeaderCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e9edf0",
        padding: "15px",
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e9edf0",
        color: "#48586a",
        padding: "10px",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const StyledMenuHeaderCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e9edf0",
        padding: "15px",
        fontWeight: "bold",
        width: 50
    },
    body: {
        fontSize: 14,
        width: 50
    },
}))(TableCell);

export const StyledMenuTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e9edf0",
        color: "#48586a",
        padding: "10px",
        width: 50
    },
    body: {
        fontSize: 14,
        width: 50
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        padding: "20px"
    },
}))(TableRow);

export const usesTableStyle = makeStyles({
    table: {
        minWidth: 700
    },
});


export const InfoIconStyled = styled(InfoIcon)`
    color: #C70066;
    cursor: pointer;
`

export const BoxStyled = styled(Box)`
    border-radius: 4px;
    padding: 8px;
    background-color: #333F48;BoxStyled
    color: #fff;
    &:before {
        background-color: #333F48;
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        bottom: -5px;
        transform: rotate(45deg);
        left: calc(50% - 6px);
    }
   
`

export const DescriptionComponent = styled.div`
    .MuiSvgIcon-root {
        cursor: pointer;
    }
`

export const DescriptionTooltip = styled.div`
    .MuiSvgIcon-root {
        color: white;
        cursor: pointer;
    }
`

