

import React, {  useEffect } from "react";
import { AppDispatch } from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import "./preselection.component.css";
import Button from "reactstrap/es/Button";
import { getPreSelection, initComponent, selectProducts } from "../redux/preselection.thunks";
import { DropdownComponent, Left, LoadingIndicatorComponent } from "../../../shared/shared";
import { deleteSelection, deleteSingleSelection, resetState, updatePreSelectProductItem } from "../redux/preselection.slice";
import { useNavigate } from "react-router-dom";
import { FlexBox, PrimaryButtonComponent } from 'shared/shared';
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import RoleForwardIcon from '@material-ui/icons/NavigateNext'
import { useTranslation } from 'react-i18next';
import { setProdutListtoFalse, setShowTableToFalse, setshowlastSelectionEmpty } from "../../hierarchyAssignment/redux/hierarchyAssignment.slice";


export const ProductManagerComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.selectproduct);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(resetState)
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(initComponent());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPreSelection());
    }, [dispatch]);


    useEffect(() => {
        dispatch(setshowlastSelectionEmpty());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setProdutListtoFalse())
        console.log("set HierarchyAssigment data empty")
    }, [dispatch]);

    if (state.command.getPreSelectionCommand.status === "pending") {
       
        return <LoadingIndicatorComponent />
    }

    const navigationToHierarchyAssignment = async () => {
        await dispatch(selectProducts())
        await dispatch(setShowTableToFalse(false));
       await navigationStatment(0);
    }

    const navigationStatment = async (id: number) => {
        await navigate(`/home/hierarchyassignment/${id}`);
    }

    return (
        <>
          <div className="Configuration">
            
            
            <div className="SelectInput" >

                {state.data.selectPreSelection.map(item => {

                    
                    return <> <DropdownComponent

                        name="Select Product"
                        key={item.index}
                        data={item.possibleStates}
                        onSelect={(value) => dispatch(updatePreSelectProductItem({ index: item.index, selectedEnum: value, possibleStates: item.possibleStates }))}
                        displayedProperties={["text"]}
                        selectedValue={item.selectedEnum}
                       

                    />
                        <Button variant="contained" className='btn btn-primary-medi' onClick={() => dispatch(deleteSingleSelection({ index: item.index, selectedEnum: item.selectedEnum, possibleStates: item.possibleStates }))}> - </Button>
                    </>
                }
                )}
            </div>

            <br/>
            <br />

           
            <FlexBox>
                <Left>
                    <PrimaryButtonComponent onClick={() => navigationToHierarchyAssignment()}   >
                        <RoleForwardIcon /> {t("button_continue")}
                     </PrimaryButtonComponent>

                    <PrimaryButtonComponent onClick={()=> dispatch((deleteSelection()))}>
                        <DeleteIcon /> {t("button_delete_all")}
                    </PrimaryButtonComponent>
                </Left>
         </FlexBox>

            </div>
        </>
        );
}


