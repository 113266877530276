import { LoadingState } from "models/loading_state";

export interface ExportOverviewState {
    loadedData: {

    };
    command: {
        exportRequest: LoadingState,
        exportRequestGetOpenWorkerQueueJobNumber: LoadingState,
        refreshList: boolean
    };
    data: {
        workerQueues: Array<IWorkerQueue>,
        workerQueue: IWorkerQueue,
        statusText: string,
        workerQueueNumber: INumberOfWorkerQueues;
    };
}


export interface IWorkerQueue {

    id: number,
    additionalData: string
    triggerType: number
    processState: number
    lastModify: string
    createdAt: string,
    processStateName: string,
    triggerTypeName: string,
    runCounter: number,
}

export interface INumberOfWorkerQueues {
    workerQueueOpenJobNumber: number;
}





export interface IWorkerQueueDate {

    id: number,
    additionalData: string
    triggerType: number
    processState: number
    lastModify: Date
    createdAt: Date,
    processStateName: string,
    triggerTypeName: string
}

export interface IStatus {
    statusText: string
}


export enum ProcessingStateEnum {
    
    Undefined = 0,
    Create = 1,
    Restart = 2,
    Started = 3,
    Finished = 4,
    Error = 5,
    Cancelled = 6
}


export enum TriggerStateEnum {
    Undefined = 0,
    TriggerExport = 1,
    ProductUpdated = 2,
    ProductInserted = 3,
    HierarchyAssigment = 4
}

