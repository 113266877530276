import { Producttunksdelete } from "admin/productmaster/Delete/redux/productmasterDelete.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initDeleteComponent } from './productmasterDelete.thunks';
import { IProductmaster } from '../../../../models/productmastertable/productmastertable.model';

const initialState: Producttunksdelete = {
    loadedData: {
        productmaster: {
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
        }

    },
    command: {
        deleteProductmaster: { status: "idle", canExecute: false }
    },
    data: {
        productmaster: {
            dynamic: false,
            abbreviation: "",
            name: "",
            id: 0,
            isCheckbox: false,
            description: "",
            indeterminateStatus: 0,
            assignment: null,
            searchCriteria: "",
            inherit: false,
            sortedId:0,
            skus: null,
        },
        showDialoge: false
    }

}



export const articletunksCreateSlice = createSlice({
    name: 'getTreeView',
    initialState,
    reducers: {
        resetStateDelete: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        getProduct: (state, action: PayloadAction<IProductmaster>) => {
            state.loadedData.productmaster = action.payload

            
            state.data.productmaster = action.payload;
        },

       
    },

    extraReducers: (builder) => {
        builder.addCase(initDeleteComponent.pending, (state) => {
           state.command.deleteProductmaster.status = "pending"
           state.command.deleteProductmaster.canExecute = false;
        }).addCase(initDeleteComponent.rejected, (state, action) => {
           state.command.deleteProductmaster.status = "error"
           state.command.deleteProductmaster.message = action.error.message;
           state.command.deleteProductmaster.canExecute = true;
        }).addCase(initDeleteComponent.fulfilled, (state, action) => {
           state.command.deleteProductmaster.status = "success"
           state.command.deleteProductmaster.canExecute = true;
        })
    }
})

export const {
    resetStateDelete,
    getProduct
    
}= articletunksCreateSlice.actions

export default articletunksCreateSlice.reducer