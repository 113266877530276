import React, { useContext, useState } from "react";
import { DialogComponent, IDialogOption } from "shared/components/dialogs/dialog.component";

interface IDialogContext {
    show: (title: string, Content: JSX.Element, options: Array<IDialogOption>) => void;
}

export const useDialog = () => {
    const context = useContext(DialogContext);
    return context;
}

export const DialogContext = React.createContext<IDialogContext>(null);

export const DialogProvider = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [Content, setContent] = useState<JSX.Element>(null);
    const [options, setOptions] = useState<Array<IDialogOption>>([]);

    const show = (title: string, Content: JSX.Element, options: Array<IDialogOption>) => {
        setTitle(title);
        setContent(Content);
        setIsOpen(true);
        setOptions(options);
    }

    return <>

        <DialogComponent
            title={title}
            isOpen={isOpen}
            options={options}
            onClose={() => { setIsOpen(false); }}>{Content ? Content : <></>}</DialogComponent>
        <DialogContext.Provider value={{ show: show }}>
            {children}
        </DialogContext.Provider>
    </>
}