import { useTranslation } from "react-i18next";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from "../shared/breadcrumbs/models/breadcrumb_route";

export const HomeBreadcrumbs = () => {
    const t = useTranslation();
    return <BreadcrumbsComponent breadcrumbs={BreadCrumsRoutes(t)} />
}

const BreadCrumsRoutes = (props) => {

    const routes: BreadcrumbRoute[] = [{
        route: "/home",
        parts: [{ title: props.t("breadcrumb_start") }]
    },
        {
            route: "/home/productmanagerpreselection",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_preselection") }
                ]
        },
        {
            route: "/home/hierarchyassignment/:id",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_preselection"), url: "/home/productmanagerpreselection" },
                    { title: props.t("breadcrumb_hierarchyassigment") }
                ]
        },
        {
            route: "/home/productmaster",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_productmaster") },
                ]
        },
        {
            route: "/home/productmaster/Add",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_productmaster"), url: "/home/productmaster" },
                    { title: props.t("breadcrumb_productmaster_add") }
                ]
        },
        {
            route: "/home/productmaster/Update",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_productmaster"), url: "/home/productmaster" },
                    { title: props.t("breadcrumb_productmaster_update") }
                ]
        },
        {
            route: "/home/export",
            parts:
                [
                    { title: props.t("breadcrumb_start"), url: "/home" },
                    { title: props.t("breadcrumb_export") }
                ]
        },

    ];

    return routes

}