import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import * as style from "./searchfield.style";



interface SearchFieldProps {
  onSearch: (searchText: string) => void;
  placeholder: string;
}

const SearchFieldComponent: React.FC<SearchFieldProps> = ({ onSearch, placeholder }) => {
    const [searchText, setSearchText] = useState("");



  return (
    <div>
      <style.Box>
        <style.TextField
          onChange={(e) => setSearchText(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.defaultPrevented) {
              return;
            }

            var handled = false;
            if (e.key !== undefined) {
              if (e.key === "Enter") {
                onSearch(e.currentTarget.value);
                handled = true;
                }
                if (e.key === "Backspace") {
                    handleSearchBackspace(); 

                }
            }

            if (handled) {
              e.preventDefault();
            }
          }}
          type="text"
          placeholder={placeholder}
        ></style.TextField>
        <style.SearchButton
          onClick={() => {
            onSearch(searchText);
          }}
        >
          <style.SearchInfoIcon
            active={
              searchText !== null &&
              searchText !== undefined &&
              searchText.length > 0

                          
            }
          >
            Suchen <SearchIcon />
          </style.SearchInfoIcon>
        </style.SearchButton>
      </style.Box>
    </div>
  );
};

export default SearchFieldComponent;

function handleSearchBackspace() {

}
