import { useNavigate } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { FlexBox, Left, PrimaryButtonComponent } from 'shared/shared';
import SaveIcon from '@material-ui/icons/Save';
import CancleIcon from '@material-ui/icons/Cancel';
import { AppDispatch } from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { deleteProducttunksItem, initDeleteComponent } from '../redux/productmasterDelete.thunks';
import { useEffect } from 'react';
import { DialogWindowOverviewComponent } from '../Dialog/dialogwindowoverview';
import { useTranslation } from 'react-i18next';
import { resetStateDelete } from '../redux/productmasterDelete.slice';

export const ProductmasterDeleteOverviewTableOverviewComponent = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.dataGridDelete);

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(resetStateDelete())
            console.log("send resquest to redux")
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(initDeleteComponent())
        console.log("send request to redux if idle statement")
    },[dispatch]);

    return (

        <div>
            <DialogWindowOverviewComponent /> : <></>
            <h2>{t("headline_label_productmastertable_delete")}</h2>

            <br />
            <h3>{t("label_abbreviation")}</h3>
            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" label={state.loadedData.productmaster.abbreviation} variant="outlined" disabled={true} />

            </div>

            <br />
            <h3>{t("label_definition")}</h3>
            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" label={state.loadedData.productmaster.name} variant="outlined" disabled={true} />
            </div>
            <br />
            <h3>{t("label_longdefinition")}</h3>
            <div className='InputTextfieldEdit' >
                <TextField id="outlined-basic" label={state.loadedData.productmaster.description} variant="outlined" disabled={true} />
            </div>

            <br />
            <FlexBox>
                <Left>
                    <PrimaryButtonComponent onClick={() => dispatch(deleteProducttunksItem())} >
                        <SaveIcon /> {t("button_delete_singlepiece")}
                    </PrimaryButtonComponent>

                    <PrimaryButtonComponent onClick={() => navigate("/home/productmastertable")} >
                        <CancleIcon /> {t("button_cancle")}
                    </PrimaryButtonComponent>

                </Left>
            </FlexBox>
        </div>

    );

};