import React from "react";
import { HeaderComponent } from "./shared/components/page_layout/header.component";
import { MenuComponent } from "./shared/components/page_layout/menu.component";
import { ContentComponent } from "./shared/components/page_layout/content.component";
import { FooterComponent } from "./shared/components/page_layout/footer.component";
import styled from "styled-components";
import AppRouterComponent from "./shared/routing/approuter.component";
import { ErrorHandlerComponent } from "./shared/shared";
import { ThemeProvider } from "@material-ui/core/styles";
import { materialTheme } from "./shared/style/theme";
import { SnackbarProvider } from 'notistack';
import "./medistradapro-webfont.woff2"
import "./App.css";
import { DialogProvider } from "shared/contexts/dialog_context";
import { BreadcrumbsRootComponent } from './shared/breadcrumbs/breadcrumbs_root.component';
import { Provider } from "react-redux";
import { reduxStore } from './app/redux_store';
import { getConfig } from "services/config.service";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
/*import { useTranslation } from 'react-i18next';*/

const LayoutArea = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;


const App = () => {
  const environmentName = getConfig().name;
  console.log(`Running MTM client app in environment: ${environmentName}`);
   /* const { t } = useTranslation();*/

    return (
        <Suspense fallback={<div></div>}>
    <Provider store={reduxStore}>
      <ThemeProvider theme={materialTheme}>
        <BrowserRouter>
          <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
            <DialogProvider>
              <LayoutArea className="layoutArea">
                <HeaderComponent />
                <MenuComponent />
                <BreadcrumbsRootComponent />
                <ContentComponent>
                  <ErrorHandlerComponent>
                    <AppRouterComponent />
                  </ErrorHandlerComponent>
                </ContentComponent>
                <FooterComponent />
              </LayoutArea>
            </DialogProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
     </Provider>
    </Suspense>
  );
};

export default App;
