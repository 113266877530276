import { ApiResult } from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import { getConfig } from './config.service';
import { INumberOfWorkerQueues, IStatus, IWorkerQueue } from "../admin/administrator/redux/export.model";

class WorkerQueueService extends HttpService {

    public async addNewWorkerQueue(workerqueue: IWorkerQueue): Promise<ApiResult<boolean>> {
        return this.postDataForData("/api/workerqueue/addworkerqueue", workerqueue);
    }

    public async getAllActiveWorkerQueue(): Promise<ApiResult<Array<IWorkerQueue>>> {
        return this.get<Array<IWorkerQueue>>("/api/workerQueue");
    }

    public async getWorkerQueueOpenJobNumber(): Promise<ApiResult<INumberOfWorkerQueues>> {
        return this.get<INumberOfWorkerQueues>("/api/workerQueue/getWorkerQueueOpenJobNumber");
    }

    public async getStatusFromAs400(): Promise<ApiResult<IStatus>> {
        return this.get<IStatus>("/api/workerQueue/As400");
    }

    public async deleteWorkerQueue(id: number): Promise<ApiResult<boolean>> {
        return this.deleteWithResult<boolean>(`/api/workerQueue/delete?id=${id}`);
    }

    public async restartWorkerQueue(id: number): Promise<ApiResult<boolean>> {
        return this.postForData(`/api/workerQueue/restart?id=${id}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ProductServiceAPI = new WorkerQueueService(TimeoutMilliseconds);

export default ProductServiceAPI;
