import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";


export const ErrorWrapper = styled.div`
    background: transparent;
    font-size: 10px;
    margin-top: 5px;
    color: red;
`;

export const useStyles = makeStyles({
    normal:{
        
    },
    error: {
        color: "red"
    },
  });