import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import ProductmasterService from "services/productmaster.service";


export const initComponentPm = createAsyncThunk(
    'articletunks/articletunks/initComponent',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const query = { ...state.selectTreeView.data.SearchCriteriaQuery, preSelectionId: state.selectproduct.loadedData.preselectionId.id };
        const response = await ProductmasterService.initComponent(query);
        handleResponseErrors(response, "init");
        return response;
    }
)

export const initComponentSku = createAsyncThunk(
    'articletunks/articletunks/initComponent',
    async (_, { getState }) => {
        const state = getState() as RootState
        const query = { ...state.selectTreeView.dataSku.SearchCriteriaQuery, preSelectionId: state.selectproduct.loadedData.preselectionId.id };
        const response = await ProductmasterService.initComponent(query);
        handleResponseErrors(response, "init");
        return response;
    }
)


export const updateProductMasterTable = createAsyncThunk(
    'articletunks/articletunks/getProductMasterTable',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.getProductMasterTable(state.dataGridUpdate.loadedData.productmaster.id);
        handleResponseErrors(response, "updateProductmaster");
        return response;
    }
);

export const SearchCriteria = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteria',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.postSearchCriteria(state.selectTreeView.data.SearchCriteria);
        handleResponseErrors(response, "saveCriteria");
        return response;
    }
);

export const DeleteSearchCriteria = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteriadelete',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.postSearchCriteriaToDeleteThem(state.selectTreeView.data.SearchCriteria);
        handleResponseErrors(response, "deleteCrtieriaSku");
        return response;
    }
);


export const SearchCriteriaSku = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteriaSku',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.postSearchCriteriaSku(state.selectTreeView.data.SearchCriteriaSku);
        handleResponseErrors(response, "SaveCriteriasku");
        return response;
    }
);

export const DeleteSearchCriteriaSku = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteriadeleteSku',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.postSearchCriteriaToDeleteThemSku(state.selectTreeView.data.SearchCriteriaSku);
        handleResponseErrors(response, "DeleteSearchCriteriasku");
        return response;
    }
);