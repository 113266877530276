import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import ProductmasterService from "services/productmaster.service";


export const initDeleteComponent = createAsyncThunk(
    'articletunks/articletunks/initDeleteComponent',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.deleteProductMasterTable(state.dataGrid.data.articledata.id);
        handleResponseErrors(response, "articletunks");
        return response;
    }
)


export const deleteProducttunksItem = createAsyncThunk(
    'articletunks/articletunks/deleteProductMasterTable',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.deleteProductMasterTable(state.dataGridDelete.loadedData.productmaster.id);
        handleResponseErrors(response, "articletunks");
        return response;
    }
)