import React, { useEffect } from "react";
import { AppDispatch } from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { FlexBox, PrimaryButtonComponent, Right } from 'shared/shared';
import RoleForwardIcon from '@material-ui/icons/NavigateNext'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as style from './productmaster.style'
import { resetState, setNewWorkerQuery } from "../redux/export.slice";
import { getWorkerQueueOpenJobNumber, getWorkerQueuedata, postNewManuelleWorkerQueue, testingAs400, restartWorkerQueue, deleteWorkerQueue } from "../redux/export.component.tunks";
import { useTranslation } from "react-i18next";
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from "@material-ui/core";

export const ExportComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.workerQueue);
    
    const { t } = useTranslation();
    let emptyTableMessage = <></>;
    let ms = 5000;

    useEffect(() => {
        return () => {
            dispatch(resetState)
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getWorkerQueuedata());
        dispatch(getWorkerQueueOpenJobNumber());
    }, [dispatch]);

    const navigationManuellerTriggerExport = async () => {
        dispatch(setNewWorkerQuery());
        await dispatch(postNewManuelleWorkerQueue());
        await dispatch(getWorkerQueuedata());
        await dispatch(getWorkerQueueOpenJobNumber());       
    }

    if (state.data.workerQueues.length === 0) {
        emptyTableMessage = <div> {t("empty_table")}</div>
    }

    useEffect(() => {
        const interval = setInterval(handleTimerFinish, ms); // Trigger the method every 10 seconds

        return () => {
            clearInterval(interval); 
        };
    },);

    const handleTimerFinish = () => {
        dispatch(getWorkerQueuedata());
    };

    const gotoAs400Api = () => {
        dispatch(testingAs400());
    };

    const retryWorker = (id: number) => {
        dispatch(restartWorkerQueue(id));    
    };

    useEffect(() => {
        if (state.command.refreshList)
            dispatch(getWorkerQueuedata());
    }, [dispatch, state.command.refreshList])

    const deleteWorker = (id: number) => {
        dispatch(deleteWorkerQueue(id));
    };

    return (
        <>
           

            <FlexBox>
                <Right>
                    <PrimaryButtonComponent onClick={() => navigationManuellerTriggerExport()}   >
                        <RoleForwardIcon /> {t("button_export")}
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>

            <div className="tableview">
                <TableContainer>
                    <Table className="testtable" aria-label="massmask table">
                        <TableHead>
                            <TableRow>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_id")}</style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_additionaldata")}</style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_triggertype")} </style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_processstate")} </style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_lastModify")} </style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_createdAt")} </style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_runCounter")} </style.StyledHeaderCell>
                                <style.StyledHeaderCell align="left">{t("table_headline_export_actions")} </style.StyledHeaderCell>
                            </TableRow>
                        </TableHead>
                        {state.data.workerQueues.map((e) =>
                            <TableBody key={e.id}>                               

                                {e.processState >= 5 ?
                                    
                                        <style.StyledTableRowError key={e.id} hover={true}>
                                            <style.StyledMenuTableCell align="left" size='medium' >
                                                {e.id}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.additionalData}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.triggerTypeName}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.processStateName}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.lastModify}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.createdAt}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.runCounter}
                                            </style.StyledMenuTableCell>      

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                            {
                                                (e.processState === 5 || e.processState === 6) ? 
                                                <>
                                                    <IconButton title={t("buttonRetry")} onClick={() => { retryWorker(e.id);}}>
                                                        <ReplayIcon />
                                                    </IconButton>
                                                    <IconButton title={t("buttonDelete")} onClick={() => { deleteWorker(e.id);}}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </>
                                                :
                                                <></>
                                            }
                                            </style.StyledMenuTableCell>                                                    
                                        </style.StyledTableRowError>
                                     :                                    
                                        <style.StyledTableRow key={e.id} hover={true}>
                                            <style.StyledMenuTableCell align="left" size='medium' >
                                                {e.id}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.additionalData}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.triggerTypeName}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.processStateName}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.lastModify}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.createdAt}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                                {e.runCounter}
                                            </style.StyledMenuTableCell>

                                            <style.StyledMenuTableCell align="left" size='medium'  >
                                            {
                                                (e.processState === 5 || e.processState === 6) ? 
                                                <>
                                                    <IconButton title={t("buttonRetry")} onClick={() => { retryWorker(e.id);}}>
                                                        <ReplayIcon />
                                                    </IconButton>
                                                    <IconButton title={t("buttonDelete")} onClick={() => { deleteWorker(e.id);}}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </>
                                                :
                                                <></>
                                            }
                                            </style.StyledMenuTableCell>
                                        </style.StyledTableRow>                                    
                                }
                               
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                <TableContainer>{emptyTableMessage}</TableContainer>
            </div>
            <br/>
            
            <PrimaryButtonComponent onClick={() => gotoAs400Api()}   >
                {t("test_as400")}
            </PrimaryButtonComponent>

            <p>{state.data.statusText }</p>
        </>
    );
}