import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useTranslation } from 'react-i18next';
import { generateAlertDialogDescription } from '../../component/productmaster.reducer';
import { deleteProducttunksItem } from '../redux/productmasterDelete.thunks';
import { setDeleteProductmasterToTrue } from '../../redux/productmaster.slice';

export const DialogWindowOverviewComponent = () => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const state = useAppSelector((state) => state.dataGridDelete);
    const dispatch: AppDispatch = useAppDispatch();


    const handleClose = () => {
        setOpen(false);
    };

    const deleteProductmaster = async () => {
        await handleClose();
        await dispatch(setDeleteProductmasterToTrue())
        await dispatch(deleteProducttunksItem());
        await window.location.reload();
    }

    const cancleProductmaster = () => {

        handleClose();
        navigate("/home/productmaster")
        state.data.showDialoge = false;
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("dialogeWindow_Delete_Title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {generateAlertDialogDescription(t("dialogeWindow_Delete_Warning01"), t("dialogeWindow_Delete_Warning02"), state.loadedData.productmaster)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteProductmaster} autoFocus>
                        {t("button_confirmation")}
                    </Button>
                    <Button onClick={cancleProductmaster} autoFocus>
                        {t("button_cancle")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}