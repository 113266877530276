import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import "./productmasterAdd.component.css";
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent } from 'shared/shared';
import SaveIcon from '@material-ui/icons/Save';
import CancleIcon from '@material-ui/icons/Cancel';
import { AppDispatch } from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { addCountry } from '../redux/productmasterAdd.thunks';
import { confirmDelete, resetState, setAbbreviation, setDynamicCheckbox, setDescription, setErrorTextField, setLongDescription, setTakeOverFromCheckbox, updateSelection, setRequireToFalseAdd } from '../redux/productmasterAdd.slice';
import { useEffect } from 'react';

import { DialogWindowOverviewComponent } from '../Dialog/dialogAddWindowOverview';
import { useTranslation } from 'react-i18next';
import { initComponentPm } from '../../redux/productmaster.thunks';
import { checkIfAbbrevationIsUsed } from "../component/productmasterAdd.reducer";

export const ProductmasterAddOverviewComponent = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.dataGridEdit);
    const stateproductmaster = useAppSelector((state) => state.dataGrid);
    
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(initComponentPm());
    },[dispatch]);  

    const setErrorTextFieldStatus = () => {
        if (state.data.errortextbox=== false) {
            dispatch(setErrorTextField(true));
        }
    };

    const countineButton = async () => {
        let status: boolean= await checkIfAbbrevationIsUsed(stateproductmaster.loadedData.articletabledata, state.data.productmaster.abbreviation);
        
       
        if (status) {
            await setErrorTextFieldStatus();
            await setconfirmDeleteStatus();
        } else {
            await dispatch(addCountry());
            await navigate("/home/productmaster");
        }
    }

    const CancelButton = async () => {
        await dispatch(setRequireToFalseAdd());
       await navigate("/home/productmaster");
    }
    const setconfirmDeleteStatus = () => {
        dispatch(confirmDelete);
    };


    if (state.command.AddProductMaster.status === "pending") {
           return <LoadingIndicatorComponent />
    }

return(
    
    <div>
        {state.data.errortextbox ? <DialogWindowOverviewComponent /> : <></>}
        

        <div className='InputTextfieldEdit' >
            <TextField id="outlined-basic"
                label={t("label_abbreviation")}
                variant="outlined"
                onChange={(e) => dispatch(setAbbreviation(e.target.value))}
                required
                error={state.data.isAbbrevationToLong}
                helperText={state.data.isAbbrevationToLong ? t("errormessage_abbrevationtolong")  : null}
            />
        </div>
       
        <br />

        <div className='InputTextfieldEdit' > 
            <TextField id="outlined-basic" label={t("label_definition")} variant="outlined" onChange={(e) => dispatch(setDescription(e.target.value))} required  />
        </div>

        <br />

        <div className='InputTextfieldEdit' >
            <TextField id="outlined-basic" label={t("label_longdefinition")} variant="outlined" onChange={(e) => dispatch(setLongDescription(e.target.value))} />
        </div>

        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(event, checked) => dispatch(setDynamicCheckbox(checked))} />} label={t("checkbox_configuration_dynamicproductmaster")} />
        </FormGroup>


        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(event, checked) => dispatch(setTakeOverFromCheckbox(checked))} />} label={t("checkbox_configuration") }/>
        </FormGroup>

        
           <DropdownComponent
            name={t("dropbox_copy_productmaster")}
            key={state.data.selectedproductmaster.index}
            data={state.loadedData.possibleProductmaster}
            displayedProperties={["description"]}
            onSelect={(value) => dispatch(updateSelection({ description: value.description, index: value.index }))}
            selectedValue={state.data.checkboxvalue === true ? state.data.selectedproductmaster : ""}
            isHidden={!state.data.checkboxvalue}

        />

        <br/>
        <br/>

        <FlexBox>
            <Left>
                <PrimaryButtonComponent onClick={() => countineButton()}  disabled={!state.data.requirefield } >
                    <SaveIcon /> {t("button_confirmation")}
                </PrimaryButtonComponent>

                <PrimaryButtonComponent onClick={() => CancelButton()} >
                    <CancleIcon /> {t("button_cancle")}
                </PrimaryButtonComponent>
                
            </Left>
         </FlexBox>
        </div>
       
);
    
};