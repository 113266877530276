import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from "@material-ui/core";
import { doesExist } from 'services/validation.service';

interface IMenuOverviewComponentProps {
    outline?: string;
    onMenuOpen: (e: any, parameter: any)  => void;
    menuItem: any;
  }  

export const MenuOverviewComponent: React.FC<IMenuOverviewComponentProps> = ({ outline, onMenuOpen, menuItem }) => {
    const outlineStyle = doesExist(outline) ? outline : "none";
    return (
        <IconButton style={{ outline: outlineStyle}} onClick={(e) => onMenuOpen(e, menuItem)}>
            <MoreVertIcon />
        </IconButton>        
    )
}


