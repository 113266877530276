import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import ProductmasterService from "services/productmaster.service";
import { ISelectionProductmaster } from '../../../../models/productmastertable/productmastertableselection.model';
import { IProductmaster } from '../../../../models/productmastertable/productmastertable.model';

export const addCountry = createAsyncThunk(
    'articletunks/articletunks/addProductMasterTable',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState

        const response = await ProductmasterService.addProductMasterTable(state.dataGridEdit.data.productmaster);
        handleResponseErrors(response, "articletunks");

        return response;
    }
)

export const setpossibleSelection = (selection: Array<ISelectionProductmaster>, tablemaster: Array<IProductmaster> ) => {

    var newDescription = "";

    tablemaster.forEach((e) => {

        if (e.abbreviation.length + e.name.length < 30) {
            newDescription ="[ "+ e.abbreviation +" ] " + e.name;
        } else {

            newDescription = "[ " + e.abbreviation + " ] " + e.name;
            newDescription=newDescription.substring(0, 30);
        }

        selection.push({index:e.id, description: newDescription})
      }
       
    )
    return selection;
}