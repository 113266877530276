import { ApiResult } from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import { getConfig } from './config.service';
import { EnumDescriptor } from "../models/enum_descriptor";
import { ProductTypeEnum } from "../models/selectproductmanager/setproducts/productSelection_Type_Enum";
import { IPreSelectionId, IProductCategory } from "../models/productmastercategory/productmastercategory.model";

class ProductService extends HttpService {
    
    public async selectProducts(product: Array<EnumDescriptor<ProductTypeEnum>>): Promise<ApiResult<IPreSelectionId>> {
        return this.postDataForData("/api/PreSelection/savenewselection", product);
    }
  
    public async getSelection(): Promise<ApiResult<Array<EnumDescriptor<ProductTypeEnum>>>> {
        return this.get<Array<EnumDescriptor<ProductTypeEnum>>>("/api/PreSelection");
    }

    public async loadAdditionalSelection(): Promise<ApiResult<IProductCategory[]>> {
        return this.get<IProductCategory[]>("/api/PreSelection/additionalSelection");
    }

    public async loadPreselectionId(id:string): Promise<ApiResult<IPreSelectionId>> {
        return this.get<IPreSelectionId>("/api/PreSelection/loadPreselectionId");
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ProductServiceAPI = new ProductService(TimeoutMilliseconds);

export default ProductServiceAPI;