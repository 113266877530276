import { BreadcrumbRoute } from "../models/breadcrumb_route";
import { useLocation } from "react-router";
import { Link, matchPath } from "react-router-dom"
import { Fragment } from "react";

interface BreadcrumbsComponentProps {
    breadcrumbs: BreadcrumbRoute[];
}

export const BreadcrumbsComponent = ({ breadcrumbs }: BreadcrumbsComponentProps) => {

    const location = useLocation();

    const renderUrl = (urlTemplate: string, matchProps): string => {
        const parameterValues = urlTemplate
            .split("/")
            .filter(urlTemplatePart => urlTemplatePart.startsWith(":"))
            .map(parameter => parameter.substr(1))
            .map(parameter => { return { parameter, value: matchProps[parameter] } });

        let url = urlTemplate;
        for (let i = 0; i < parameterValues.length; i++) {
            url = url.replace(`:${parameterValues[i].parameter}`, parameterValues[i].value)
        }
        return url;
    }


    return (
        <>{
            breadcrumbs.map((breadcrumb, index) => {

                const match = matchPath({ path: breadcrumb.route, caseSensitive: false, end: true, }, location.pathname);

                if (match != null) {

                    const breadcrumbs = breadcrumb.parts.map((part, index) => {
                        if (index < breadcrumb.parts.length - 1) {
                            if (part.url) {
                                const urlWithParams = renderUrl(part.url, match.params);
                                return <Fragment key={index}><Link to={urlWithParams}>{part.title}</Link> / </Fragment>
                            } else {
                                return <span key={index}>{`${part.title} / `}</span>;
                            }
                        } else {
                            return <span key={index}>{part.title}</span>;
                        }
                    });
                    return breadcrumbs;
                }
                return <Fragment key={index}></Fragment>
            })
        }</>
    );
}