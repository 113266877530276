import React from "react";
import { NotFoundComponent } from "../shared";
import { HomeComponent } from "../../home/home.component";

import { AdminComponent } from '../../admin/admin.component';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const AppRouterComponent = () => {
  return (
    <Routes>
      <Route element={<HomeComponent />} path="/home" />
      <Route element={<AdminComponent />} path="/home/*" />
  
      <Route element={<NotFoundComponent />} path ="*" />
      <Route element={<Navigate to="/home" replace />} path="/" />
    </Routes>
  );
};
export default AppRouterComponent;
