import CheckIcon from '@material-ui/icons/Check';
import React from "react";
import colors from "shared/style/colors";
import styled from "styled-components";

const ColorGreen = styled.span`
  color: ${colors.darkGreenMedi};
`

export const PublishedIconComponent = () => {
    return <ColorGreen><CheckIcon /></ColorGreen>
}