import { createSlice } from '@reduxjs/toolkit';
import { ExportOverviewState, IWorkerQueue, ProcessingStateEnum, TriggerStateEnum} from './export.model';
import { deleteWorkerQueue, getWorkerQueueOpenJobNumber, getWorkerQueuedata, restartWorkerQueue, testingAs400 } from './export.component.tunks';


const initialState: ExportOverviewState = {
    loadedData: {

    },
    command: {
        exportRequest: { status: "idle", canExecute: false },
        exportRequestGetOpenWorkerQueueJobNumber:{ status: "idle", canExecute: false },
        refreshList: false
    },
    data: {
        workerQueues: [],
        workerQueue: {

            id: 0,
            additionalData: "",
            triggerType: null,
            processState: null,
            lastModify: "",
            createdAt: "",
            processStateName: "",
            triggerTypeName:"",
            runCounter: 0
        },
        statusText: "",
        workerQueueNumber: { workerQueueOpenJobNumber: 0}
    }
}

export const exportCreateSlice = createSlice({
    name: 'getWorkerQueueInformation',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        setNewWorkerQuery: (state) => {

            let newQueue: IWorkerQueue ={
                id: 0,
                triggerType: 1,
                processState: 1,
                additionalData: "",
                lastModify: "",
                createdAt: "",
                processStateName: "",
                triggerTypeName:"",
                runCounter: 0
            }

            state.data.workerQueue = newQueue;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getWorkerQueuedata.pending, (state) => {
            state.command.exportRequest.status = "pending";
            state.command.exportRequest.canExecute = false;
        }).addCase(getWorkerQueuedata.rejected, (state, action) => {
            state.command.exportRequest.status = "error";
            state.command.exportRequest.canExecute = true;
        }).addCase(getWorkerQueuedata.fulfilled, (state, action) => {
            state.command.exportRequest.status = "success";
            let data = action.payload.getData();

            if (data.length !== 0) {
                
                data.forEach((entry) => {
                   
                    entry.createdAt = new Date(entry.createdAt).toLocaleString();
                    entry.lastModify = new Date(entry.lastModify).toLocaleString();

                    //processState format
                    switch (entry.processState) {
                        case ProcessingStateEnum.Undefined: entry.processStateName = "Undefined"; break;
                        case ProcessingStateEnum.Create: entry.processStateName = "Create"; break;
                        case ProcessingStateEnum.Restart: entry.processStateName = "Restart"; break;
                        case ProcessingStateEnum.Started: entry.processStateName = "Started"; break;
                        case ProcessingStateEnum.Finished: entry.processStateName = "Finished"; break;
                        case ProcessingStateEnum.Error: entry.processStateName = "Error"; break;
                        case ProcessingStateEnum.Cancelled: entry.processStateName = "Cancelled"; break;
                    }

                    //triggerType format
                    switch (entry.triggerType) {
                        case TriggerStateEnum.Undefined: entry.triggerTypeName = "Undefined"; break;
                        case TriggerStateEnum.TriggerExport: entry.triggerTypeName = "TriggerExport"; break;
                        case TriggerStateEnum.ProductUpdated: entry.triggerTypeName = "ProductUpdate"; break;
                        case TriggerStateEnum.ProductInserted: entry.triggerTypeName = "ProductInsert"; break;
                        case TriggerStateEnum.HierarchyAssigment: entry.triggerTypeName = "HierarchyAssigment"; break;
                    }
                });
            }
            
            state.data.workerQueues = data;
        }).addCase(testingAs400.fulfilled, (state, action) => {
            state.data.statusText = action.payload.getData().statusText;
        }).addCase(getWorkerQueueOpenJobNumber.pending, (state, action) => {
            state.command.exportRequestGetOpenWorkerQueueJobNumber.status = "pending";
            state.command.exportRequestGetOpenWorkerQueueJobNumber.canExecute = false;
        }).addCase(getWorkerQueueOpenJobNumber.rejected, (state, action) => {
            state.command.exportRequestGetOpenWorkerQueueJobNumber.status = "error";
            state.command.exportRequestGetOpenWorkerQueueJobNumber.canExecute = true;
        }).addCase(getWorkerQueueOpenJobNumber.fulfilled, (state, action) => {
            state.data.workerQueueNumber.workerQueueOpenJobNumber= action.payload.getData().workerQueueOpenJobNumber;           
            state.command.exportRequestGetOpenWorkerQueueJobNumber.status = "success";
            state.command.exportRequestGetOpenWorkerQueueJobNumber.canExecute = false;
        })
        .addCase(restartWorkerQueue.pending, (state, action) => {
            state.command.refreshList = false;
        })
        .addCase(restartWorkerQueue.fulfilled, (state, action) => {
            state.command.refreshList = true;
        })
        .addCase(deleteWorkerQueue.pending, (state, action) => {
            state.command.refreshList = false;
        })
        .addCase(deleteWorkerQueue.fulfilled, (state, action) => {
            state.command.refreshList = true;
        });
    }
})

export const {
    resetState,
    setNewWorkerQuery
} = exportCreateSlice.actions

export default exportCreateSlice.reducer

